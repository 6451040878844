import ReceiptDetailSection from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { useReceiptOrder } from "../../ReceiptContext";
import { buildHeaderTag, buildHeaderValue } from "../utils";


export const OrderPaymentStatus = () => {
  const order = useReceiptOrder()
  const headerValue = buildHeaderValue(order);
  const headerRightView = buildHeaderTag(order);

  const items = [{
    title: translate("receiptDetailScreen.paymentStatus") as string,
    value: headerValue,
    rightView: headerRightView,
  }];

  return <ReceiptDetailSection data={items} />;
};
