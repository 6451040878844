import { Flavor } from "./flavor";
import { color2 } from "src/styles";

class ShanghaiFlavor extends Flavor {
  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }
}

const Singleton = new ShanghaiFlavor();
export default Singleton;
