import { LogoPWC } from "src/components/LogoPWC";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class PWCFlavor extends Flavor {
  getMainColor() {
    return color2.PWC;
  }

  getDashLineColor() {
    return color2.PWC;
  }

  getCircleLogo() {
    return <LogoPWC />
  }
}

const Singleton = new PWCFlavor();
export default Singleton;
