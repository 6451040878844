import { StyleSheet } from "react-native";
import { color2 } from "src/styles/colors";
import { font } from "src/styles/themes";
import { Flavor } from "src/lib/flavors";
import { dpToPx } from "src/styles";

export const baseStyles = (flavor: Flavor) =>
  StyleSheet.create({
    headline: {
      color: color2.BLUE_DARK,
      fontFamily: flavor.getPrimaryFont(),
      fontSize: font.size.header,
      lineHeight: dpToPx(40),
    },
    caption: {
      color: color2.GRAY4,
      fontFamily: flavor.getPrimaryFontLightest(),
      fontSize: font.size.caption,
      lineHeight: dpToPx(24),
    },
    receiptHeadline: {
      color: color2.BLUE_DARK,
      fontFamily: flavor.getSecondaryFontBold(),
      fontSize: font.size.titleText,
    },
    receiptCaption: {
      color: color2.BLUE_DARK,
      fontFamily: flavor.getSecondaryFont(),
      fontSize: font.size.receiptCaption,
    },
    inputTextField: {
      color: color2.BLUE_DARK,
      fontFamily: flavor.getPrimaryFontLightest(),
      fontSize: font.size.input,
    },
    titleText: {
      color: color2.BLUE_DARK,
      fontFamily: flavor.getPrimaryFontLight(),
      fontSize: font.size.titleText,
      lineHeight: dpToPx(19),
    },
    subtitleText: {
      color: color2.GRAY4,
      fontFamily: flavor.getPrimaryFontLight(),
      fontSize: font.size.subtitleText,
      lineHeight: dpToPx(15),
    },
  });
