import React from "react";
// import Svg, { Path, G } from "react-native-svg";
import { color2 } from "src/styles";

export const LogoHEB = () => (
  <svg width={306} height={65} viewBox="0 0 956 337">
    <path
      fill={color2.HEB_RED_LOGO}
      d="M901.7 294.4c0-6.7 5.1-11.7 11.6-11.7 6.4 0 11.4 5 11.4 11.7 0 6.8-5 11.8-11.4 11.8-6.5 0-11.6-5-11.6-11.8zm11.5 14.2c7.7 0 14.3-6 14.3-14.2s-6.6-14.1-14.3-14.1c-7.8 0-14.4 5.9-14.4 14.1s6.6 14.2 14.4 14.2zm-3-13h2.8l4.3 7h2.8l-4.7-7.2c2.4-.3 4.2-1.6 4.2-4.5 0-3.2-1.9-4.7-5.8-4.7h-6.2v16.3h2.5v-6.9zm0-2.2v-5.1h3.4c1.7 0 3.6.4 3.6 2.4 0 2.5-1.9 2.7-4 2.7h-3zM775.6 13.9H174.4c-41.3 0-79.9 16.1-109.1 45.2C36.1 88.2 20 127.1 20 168.3s16.1 80 45.2 109.2c29.3 29.1 67.9 45.2 109.2 45.1h601.2c41.3 0 79.9-16 109-45.2 29.3-29.2 45.3-68 45.3-109.1.1-85.2-69.1-154.4-154.3-154.4"
    />
    <path
      fill={color2.WHITE}
      d="M775.7 303.3c74.6 0 135.1-60.5 135.1-135.1S850.2 33 775.7 33.1l-601.2.1c-74.7 0-135.2 60.6-135.2 135.1 0 74.7 60.7 135.1 135.2 135h601.2z"
    />
    <path
      fill={color2.HEB_RED_LOGO}
      d="M775.7 290c67.3 0 121.9-54.4 121.9-121.7 0-67.3-54.6-121.9-121.9-121.9l-601.2.2c-67.4 0-121.9 54.6-121.8 121.8 0 67.3 54.5 122 121.8 121.9l601.2-.3"
    />
    <g>
      <path
        fill={color2.WHITE}
        d="M247.3 188.4l.8 47.4 55.4-.1 8-134.8h-65.7l.9 60.3h-39.2l.8-60.3h-65.6l8.2 134.9h55.3l.7-47.4zM371.5 188.9v-27.2l-39.7.1v27.1zM620.1 188.9l-.2-27.2h-39.7l-.1 27.2zM529.9 161.2H459l.3-23.5h98.9l2.6-36.8H394.3l5.6 134.7h153.6l2.1-25.3h-97.5l.4-21.9h70.4zM743.7 199.1c-1.2 13.2-15.3 12.1-25 12.1h-11.9l.4-25h18.6c9.1.1 19 2 17.9 12.9m-15-63.8c9.5 0 20.4 3.9 20.1 13.1-.8 11.1-10.1 14-21.9 14h-19.3l.5-27.1h20.6zm40.8-34.4H643l8 134.7h110.2c32.9 0 41.2-15.6 43.5-28.2 2.3-11.8 3.6-29.7-20.9-32.2 19.1-4.2 28.6-16 29-37.2.2-13.5-7.4-37.3-43.3-37.1z"
      />
    </g>
  </svg>
);
