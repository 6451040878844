import { StyleSheet } from "react-native";
import { color2, dpToPx } from "../../../../styles";
import { font } from "../../../../styles/themes";

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
  },
  column: { display: "flex", flexDirection: "column" },
  text: {
    fontSize: dpToPx(14),
    color: color2.BLUE_DARK,
    fontFamily: font.family.montserrat,
    fontWeight: "500",
    textAlign: "right",
    paddingVertical: 8,
  },
});
