import { isEmpty } from 'ramda';
export const toQueryParms = (query: Record<string, string | number>)  => {
    return Object.entries(query).filter(([, value]) => !isEmpty(value)).reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '')
}

export function hexToRgba(hex: string, alpha: number): string {
    // Remove '#' if it exists
    hex = hex.replace('#', '');

    // Parse hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Validate alpha value
    if (alpha < 0 || alpha > 1) {
        console.error('Alpha value must be between 0 and 1.');
        return hex;
    }

    // Validate parsed values
    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        console.error('Invalid hex color format.');
        return hex;
    }

    // Validate hex string length
    if (hex.length !== 6) {
        console.error('Hex color must be 6 characters long (excluding #).');
        return hex;
    }

    // Return RGBA string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
