import { FC, useContext } from "react";
import { MasterContext } from "../../lib/masterContext";
import { isEmpty, isNil, startsWith } from "ramda";
import { cssStyles } from "../HeaderLogo/styles";

export const ReceiptLogo: FC = () => {
  const { flavor, appConfig} = useContext(MasterContext);

  const headerFromConfig = appConfig.receiptHeaderLogo;

  if (isEmpty(headerFromConfig) || isNil(headerFromConfig)) {
    return flavor.getReceiptLogo();
  } else if (startsWith('http', headerFromConfig)) {
    const bgStyle = {
      backgroundImage: `url('${headerFromConfig}')`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };
    return (
      <div style={cssStyles.wrapper}>
        <div
          style={{
            ...cssStyles.logoWrapper,
            ...bgStyle,
          }}
        ></div>
      </div>
    );
  }
  return <p dangerouslySetInnerHTML={{ __html: headerFromConfig }}></p>;
};
