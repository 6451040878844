import React from "react";

import { MasterContext } from "src/lib/masterContext";
import { ReceiptsListTable } from "./ReceiptsListTable";
import { ReceiptInfo } from "../ReceiptsForm/types";
import { translate } from "src/lib/i18n";

export interface ReceiptsResultProps {
  receipts?: ReceiptInfo[];
}

export const ReceiptResult: React.FC<ReceiptsResultProps> = ({ receipts }) => {
  const { appConfig } = React.useContext(MasterContext);

  const homeOrderNotReadyMsg =
    appConfig.messages.homeOrderNotReadyMsg ??
    translate("messageFallbacks.homeOrderNotReadyMsg");

  if (!receipts?.length) {
    return (
      <span dangerouslySetInnerHTML={{ __html: homeOrderNotReadyMsg }}></span>
    );
  } else if (receipts && receipts?.length) {
    return (
      <ReceiptsListTable receipts={receipts} />
    );
  }
  return <></>;
};
