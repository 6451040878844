import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoMiami } from "src/components/LogoMiami";

class MiamiFlavor extends Flavor {
  getMainColor() {
    return color2.MIAMI_MAIN;
  }

  getDashLineColor() {
    return color2.MIAMI_MAIN;
  }

  getCircleLogo() {
    return <LogoMiami />;
  }
}

const Singleton = new MiamiFlavor();
export default Singleton;
