import { color2 } from "../../styles";
import { Flavor } from "./flavor";
import { LogoPhiladelphiaEagles } from "src/components/LogoPhiladelphiaEagles";

class PhiladelphiaEagles extends Flavor {
  getMainColor() {
    return color2.PHILADELPHIA_EAGLES;
  }

  getDashLineColor() {
    return color2.PHILADELPHIA_EAGLES;
  }

  getCircleLogo() {
    return <LogoPhiladelphiaEagles />;
  }
}

const Singleton = new PhiladelphiaEagles();
export default Singleton;
