import React from "react";

import { DimensionValue, Image } from "react-native";
import { pick } from "ramda";

export type ImageLogoProps = {
  uri: string;
  maxWidth?: DimensionValue;
  maxHeight?: DimensionValue;
};

export const ImageLogo = (props: ImageLogoProps) => (
  <Image
    resizeMode="contain"
    style={{
      width: 375,
      height: 150,
      ...pick(['maxWidth','maxHeight'], props),
    }}
    source={{ uri: props.uri }}
  />
);
