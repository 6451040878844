import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoBoxLL } from "src/components/LogoBoxLL";

class BoxLLFlavor extends Flavor {
  getMainColor() {
    return color2.BOXLL_RED;
  }

  getCircleLogo() {
    return <LogoBoxLL />;
  }

  getDashLineColor() {
    return color2.BOXLL_RED;
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      isPrivacyPolicyEnabled: true,
      privacyPolicyLink: "https://www.lekkerland.de/boxll-datenschutz/",
      isDataProtectionEmailEnabled: true,
    };
  }
}

const Singleton = new BoxLLFlavor();
export default Singleton;
