import React from "react";
import { View, Dimensions, StyleProp, ViewStyle } from "react-native";
import { IUseFlavor, withFlavor } from "src/lib/hooks/useFlavor";
import { styles } from "./style";

type IDashLine = IUseFlavor & {
  width?: number;
  spacing?: number;
  style?: StyleProp<ViewStyle>;
};

const screenWidth = Math.ceil(Dimensions.get("window").width);

const DashLineEl: React.FC<IDashLine> = ({ style, flavor }) => {
  const css = styles(flavor.getMainColor());

  return (
    <View style={[css.wrapper, style]}>
      <View style={[css.line, { width: screenWidth }]} />
    </View>
  );
};

export const DashLine = withFlavor(DashLineEl);
