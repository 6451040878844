import { Platform } from "react-native";
import styled, { css } from "styled-components/native";
import { dpToPx } from "../../styles";

export const Wrapper = styled.View`
  ${Platform.select({
    ios: css`
      padding: 0 ${dpToPx(16)}px;
    `,
  })};
`;
