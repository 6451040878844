import React from 'react'
import { Flavor } from './flavor'
import { color2 } from '../../styles'
import { LogoVerizon } from '../../components/LogoVerizon'

class VerizonFlavor extends Flavor {
  getMainColor() {
    return color2.PENSKE_GRAY
  }

  getDashLineColor() {
    return color2.PENSKE_GRAY
  }

  getCircleLogo() {
    return <LogoVerizon />
  }
}

const Singleton = new VerizonFlavor()
export default Singleton
