import { LogoBrighton } from "src/components/LogoBrighton";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class BrightonFlavor extends Flavor {
  getMainColor() {
    return color2.BRIGHTON;
  }

  getDashLineColor() {
    return color2.BRIGHTON;
  }

  getCircleLogo() {
    return <LogoBrighton />;
  }
}

const Singleton = new BrightonFlavor();
export default Singleton;
