import React from 'react'

export const LogoPhoenixSuns = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={464.773}
    height={386.905}
    viewBox="0 0 122.971 102.369"
  >
    <defs>
      <clipPath id="phoenix-a" clipPathUnits="userSpaceOnUse">
        <path d="M0 290.179h348.58V0H0Z" />
      </clipPath>
    </defs>
    <path
      d="m0 0-34.111-33.961-56.103 29.405-10.043-55.235-51.82-11.231 30.66-18.479-30.855-15.477 26.22-2.278-107.821-83.857 108.461 57.615-116.345-88.565 123.834 64.551-132.281-99.457 145.786 77.688-153.106-110.898 169.628 92.892-115.024-92.892 139.568 80.206-2.293-16.172 23.274 12.935 52.569-49.712 27.985 84.079 35.508 5.084-15.619 48.523L91.056-96.55 47.323-78.089l12.018 50.998-40.352-18.471z"
      style={{
        fill: "#f7960f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.35278 0 0 -.35278 90.849 0)"
    />
    <g clipPath="url(#phoenix-a)" transform="matrix(.35278 0 0 -.35278 0 102.369)">
      <path
        d="M0 0c-1.918-46.616-41.269-82.837-87.896-80.909-46.597 1.921-82.821 41.269-80.895 87.881 1.92 46.624 41.274 82.836 87.883 80.916C-34.292 85.957 1.934 46.613 0 0"
        style={{
          fill: "#f1511b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(310.397 164.196)"
      />
      <path
        d="M0 0c-46.606 1.939-82.837 41.285-80.906 87.896.88 21.571 9.788 40.893 23.715 55.26-21.677-14.473-36.399-38.733-37.551-66.795-1.926-46.611 34.298-85.96 80.895-87.88 25.056-1.042 48 8.961 64.168 25.648C35.973 4.544 18.57-.763 0 0"
        style={{
          fill: "#b84911",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(236.348 94.806)"
      />
      <path
        d="M0 0a81.966 81.966 0 0 1-.363 8.967c-4.867 3.397-10.662 5.984-17.522 6.867-1.302.166-2.547.237-3.808.333a104.743 104.743 0 0 1-3.763 16.36c.872-.056 1.76-.091 2.64-.158a248.629 248.629 0 0 0 17.572-1.955 85.42 85.42 0 0 1-2.672 6.327 259.095 259.095 0 0 1-19.773 2.261h-.059a100.42 100.42 0 0 1-7.819 15.65c4.318.883 8.166 2.648 11.286 5.595a84.35 84.35 0 0 1-4.971 4.667c-2.714-2.496-6.224-3.822-10.262-4.358-.624.865-1.238 1.729-1.88 2.566-5.737 7.468-12.354 13.842-19.642 19.032a84.325 84.325 0 0 1-19.918 3.233c-.389.013-.763.004-1.147.021 12.834-5.049 24.302-13.525 33.519-24.884-8.79.849-18.925 3.798-28.948 6.732-2.957.88-5.866 1.734-8.673 2.507-17.071 4.715-30.19 5.47-40.036 4.614a84.84 84.84 0 0 1-19.704-15.511c10.641 4.435 32.089 12.012 58.124 4.806a566.534 566.534 0 0 0 8.556-2.457c11.514-3.384 24.414-7.155 35.578-7.278a92.475 92.475 0 0 0 7.937-14.471c-2.19.101-4.366.174-6.54.208-54.241.934-100.743-15.303-126.559-41.189a84.674 84.674 0 0 1 1.765-15.068c7.281 10.449 18.058 19.885 31.967 27.712 26.26 14.757 61.093 22.526 98.105 21.883 1.272-.019 2.558-.075 3.827-.117a95.247 95.247 0 0 0 4.15-16.581C-52.865 15.306-68.325.424-86.071-15.407c-15.93-14.255-35.365-28.454-63.803-36.999a83.707 83.707 0 0 1 8.742-9.223c26.801 10.791 44.597 27.593 59.644 41.037 17.075 15.28 31.727 29.918 53.266 30.639a99.524 99.524 0 0 0 .027-18.139c-2.894-33.097-22.051-61.148-48.182-74.99a83.579 83.579 0 0 1 19.32 4.168 93.263 93.263 0 0 1 6.101 5.489c17.139 16.874 27.775 39.861 29.951 64.709.544 6.217.541 12.386.037 18.464 1.072-.115 2.145-.205 3.249-.392C-10.38 8.155-4.555 4.454 0 0"
        style={{
          fill: "#151b1f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(310.443 166.697)"
      />
    </g>
  </svg>
)
