import "mobx-react-lite/batchingForReactDom";
import { decorate, observable, action, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { IReceiptItem } from "../utils";

export class Store {
  public receiptItems: IReceiptItem[] = [];
  public temporaryReceipts: IReceiptItem[] = [];

  public addTempReceiptItem = (item: IReceiptItem) => {
    this.temporaryReceipts.push(item);
  };

  public addReceiptItem = (item: IReceiptItem) => {
    this.receiptItems.push(item);
  };

  public removeTempReceiptItems = () => {
    this.temporaryReceipts = [];
  };

  public removeTempItem = (item: IReceiptItem) => {
    this.temporaryReceipts = this.temporaryReceipts.filter(
      (i) => i.id !== item.id
    );
  };

  public changeTempItemQuantity = (item: IReceiptItem, quantity: number) => {
    const index = this.temporaryReceipts.findIndex((i) => i.id === item.id);
    this.temporaryReceipts[index].quantity = quantity;
  };

  public receiptItemsWithOrderId = (orderId: string) => {
    const list = this.receiptItems.filter((i) => i.orderId === orderId);
    return toJS(list);
  };

  public saveReceiptItems = (saveItems: IReceiptItem[], orderId: string) => {
    saveItems.forEach((item) => {
      item.orderId = orderId;
      const result = this.receiptItems.find((i) => i.id === item.id);
      if (result) {
        result.image = item.image;
        result.totalPrice = item.totalPrice;
      } else {
        this.addReceiptItem(item);
      }
    });
  };
}

decorate(Store, {
  receiptItems: [observable, persist("list") as any],
  temporaryReceipts: observable,
  addTempReceiptItem: action,
  removeTempReceiptItems: action,
  removeTempItem: action,
  addReceiptItem: action,
  receiptItemsWithOrderId: action,
});

const persistStore = new Store();
// This persist data maybe temporary
const hydrate = create({ storage: AsyncStorage, jsonify: true });
hydrate("RECEIPT_DATA", persistStore);
export default persistStore;
