import React, { FC } from "react";
import QRCode from "react-qr-code";
import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";
import styled from "styled-components";
import { config } from "src/lib/config";
import HeaderLogo from "src/components/HeaderLogo";

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 25% 20% auto min-content;
  gap: 2%;
  height: 100vh;

  > div {
    margin: auto;
    text-align: center
  }
`;

const Poster: FC = () => {
  const webReceiptURL = config.messages.poster.receiptURL;

  let message = config.messages.poster.content ?? ''
  if (webReceiptURL) {
    message = message.replace(
      "#{receiptURL}",
      webReceiptURL
    );
  }

  return (
      <Wrapper>
        <div>
          <HeaderLogo />
        </div>
        <div>
          <QRCode value={webReceiptURL ?? ''} style={{ height: "100%" }} />
        </div>
        <div>
          <h5 style={{ whiteSpace: "pre-line" }}>
            {message}
          </h5>
        </div>
        <div>
          <PoweredByAiFi />
        </div>
      </Wrapper>
  );
};

export default Poster;
