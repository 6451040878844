import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoStreet } from "src/components/LogoStreet";

class StreetFlavor extends Flavor {
  getMainColor() {
    return color2.STREET;
  }

  getCircleLogo() {
    return <LogoStreet />;
  }
}

const Singleton = new StreetFlavor();
export default Singleton;
