import * as React from "react";
import { ActivityIndicator } from "react-native";
import { IUseFlavor, withFlavor } from "../../lib/hooks/useFlavor";
import styled from "../../styles";

const CenterView = styled.View`
  width: 100%;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CenterActivityIndicator = ({ flavor }: IUseFlavor) => (
  <CenterView>
    <ActivityIndicator color={flavor.getMainColor()} />
  </CenterView>
);

export default withFlavor(CenterActivityIndicator);
