import { useContext } from "react";
import { MasterContext } from "../masterContext";
import { CurrencyFormat } from "../../../../shared/interface";

export function useCurrencyFormatter(
  currencyCode: CurrencyCode,
  formatOverride?: CurrencyFormat
) {
  const { appConfig } = useContext(MasterContext);
  const currencyFormat = formatOverride ?? appConfig.currencyFormat;

  const numberFormat = new Intl.NumberFormat(appConfig.locale, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: (
      {
        code: "code",
        symbol: "narrowSymbol",
        plain: "narrowSymbol", // currency symbol will be rejected after formatting
      } as const
    )[currencyFormat],
  });

  const formatCurrency = (amount: number | string) => {
    const numberAmount =
      typeof amount === "number" ? amount : parseFloat(amount);

    if (currencyFormat === "plain") {
      return numberFormat
        .formatToParts(numberAmount)
        .filter((p) => p.type !== "currency")
        .map(p => p.value)
        .join("");
    } else {
      return numberFormat.format(numberAmount);
    }
  };

  return { formatCurrency };
}
