import React, { useContext } from "react";
import { cssStyles } from "./styles";
import { MasterContext } from "src/lib/masterContext";
import { ReceiptLogoBlock } from "../blocks/ReceiptLogoBlock";
import { OrderPaymentStatus } from "../blocks/OrderPaymentStatus";
import { TextBlock } from "../blocks/TextBlock";
import { OrderIdWithDateBlock } from "../blocks/OrderIdWithDateBlock";

export const ReceiptHeader = () => {
  const { featureFlags } = useContext(MasterContext);
  return (
    <div style={cssStyles.wrapper}>
      <ReceiptLogoBlock />
      {featureFlags.orderDetailsShowDetailsAtTheTop && <OrderIdWithDateBlock />}
      {featureFlags.orderDetailsShowHeader && <OrderPaymentStatus />}
      {featureFlags.orderDetailsShowProductsListTitle && (
        <TextBlock
          blockSettings={{
            content: {
              type: "translation",
              translationKey: "receiptDetailScreen.listOfProducts",
            },
            style: "title",
            textAlign: "center",
          }}
        />
      )}
    </div>
  );
};

export default ReceiptHeader;
