import { SafeAreaView } from "react-native";
import { color2 } from "../../styles";
import styled from "styled-components/native";

const StyledScreen = styled(SafeAreaView)`
  flex: 1;
  background: ${color2.WHITE};
`;

export default StyledScreen;
