import React, { useCallback } from "react";
import { Dimensions, FlatList } from "react-native";

import { ModalWrapper } from "./styles";
import ProductItem from "./ProductItem";
import ProductSearch from "./ProductSearch";
import { filterProduct } from "./utlis";
import { dpToPx } from "../../../styles";
import { SlideModal } from "../../v2/SlideModal";
import CenterActivityIndicator from "src/components/CenterActivityIndicator";
import { translate } from "src/lib/i18n";
import api from "src/lib/api/api";
import { useApi } from "src/lib/ApiProvider";
import { toQueryParms } from "src/utils";

const { height } = Dimensions.get("window");

type ProductSearchModalProps = {
  onDismiss: () => void;
  onAdd: (product: ApiProductsDetail) => void;
};

const ProductSearchModal: React.FC<ProductSearchModalProps> = ({
  onDismiss,
  onAdd,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState<string>("");
  const [products, setProducts] = React.useState<ApiProductsDetail[]>([]);
  const { authApi } = useApi();

  const loadProducts = useCallback(() => {
    if (search) {
      authApi
        .get(`/api/customer/v2/products?${toQueryParms({ name: search })}`)
        .then((response) => {
          setProducts(response.data as ApiProductsDetail[]);
          setLoading(false);
        })
        .catch(api.handleError);
    } else {
      setProducts([]);
    }
  }, [authApi, search]);

  React.useEffect(() => {
    loadProducts();
  }, [loadProducts, search]);

  return (
    <SlideModal
      contentHeight={height - dpToPx(5)}
      dismissCallback={onDismiss}
      headChildren={<ProductSearch onChange={setSearch} />}
      closeText={translate("slideModal.close")}
    >
      <ModalWrapper>
        {loading ? (
          <CenterActivityIndicator />
        ) : (
          <FlatList
            keyExtractor={(item) => item.id}
            data={filterProduct(products) as ApiProductsDetail[]}
            renderItem={({ item }: { item: ApiProductsDetail }) => (
              <ProductItem item={item} onAdd={onAdd} />
            )}
          />
        )}
      </ModalWrapper>
    </SlideModal>
  );
};

export default ProductSearchModal;
