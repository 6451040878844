import React from "react";
import { Back } from "../../assets/icons/Back";
import { Wrapper } from "./styles";
import { color2 } from "../../styles";

export const BackButton = () => (
  <Wrapper>
    <Back color={color2.BLACK} />
  </Wrapper>
);
