import React from "react";
import { Text } from "react-native";
import { LogoCircle } from "src/components/LogoCircle";

import { cssStyles } from "./styles";

type IReceiptHeader = {
  text?: string;
};

const HeaderLogo = ({ text }: IReceiptHeader) => {
  return (
    <div style={cssStyles.wrapper}>
      <div style={cssStyles.logoWrapper}>
        <LogoCircle />
        <Text>{text}</Text>
      </div>
    </div>
  );
};

export default HeaderLogo;
