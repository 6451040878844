import { StyleSheet } from "react-native";
import { Flavor } from "src/lib/flavors";
import { color2, dpToPx } from "src/styles";
import { font } from "src/styles/themes";

export const styles = (flavor: Flavor) =>
  StyleSheet.create({
    title: {
      color: color2.BLUE_DARK,
      fontFamily: flavor.getPrimaryFont(),
      fontSize: font.size.header,
      lineHeight: dpToPx(40),
    },
    subtitle: {
      color: color2.GRAY4,
      fontFamily: flavor.getPrimaryFontLightest(),
      fontSize: font.size.caption,
      marginTop: dpToPx(24),
      lineHeight: dpToPx(24),
    },
    wrapper: {
      flex: 1,
      padding: dpToPx(24),
    },
    content: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      minHeight: dpToPx(86),
    },
  });
