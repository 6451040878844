import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoDG } from "src/components/LogoDG";

class DGFlavor extends Flavor {
  getMainColor() {
    return color2.DG_MAIN;
  }

  getDashLineColor() {
    return color2.DG_MAIN;
  }

  getCircleLogo() {
    return <LogoDG />;
  }
}

const Singleton = new DGFlavor();
export default Singleton;
