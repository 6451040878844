import { SEARCH_TYPE_VALUES } from "src/lib/common/searchTypes";
import * as Yup from "yup";

const digitsOnly = (value: string | undefined) => {
  return value ? /^\d+$/.test(value) : false;
};

const expDateTest = (value: string | undefined) => {
  return value ? /^\d\d\/\d\d$/.test(value) : false;
};

export const buildReceiptSearchValidationSchema = (
  requireEmailRegistration: boolean,
  expDateRequired: boolean,
  ccCardBinLength: number | null
) => {
  let email = Yup.string().email("Must be a valid email")
  if (requireEmailRegistration) {
    email = email.required("The field is required")
  }

  return Yup.object().shape({
    searchType: Yup.string().oneOf([...SEARCH_TYPE_VALUES]),

    customerPaymentInstrumentLast4: Yup.string().when("searchType", {
      is: (val: string) => ["card", "google", "apple"].includes(val),
      then: (schema) =>
        schema
          .required("The field is required")
          .length(4, "The field requires 4 characters")
          .test(
            "Digits only",
            "The field should only contains digits",
            digitsOnly
          ),
    }),

    ...(expDateRequired && {
      customerPaymentInstrumentExpDate: Yup.string().when("searchType", {
        is: (val: string) => val === "card",
        then: (schema) => {
          return schema
            .required("The field is required")
            .test(
              "Format",
              "The field is not in the valid format",
              expDateTest
            );
        },
      }),
    }),

    ...(ccCardBinLength !== null && {
      customerPaymentInstrumentBin: Yup.string().length(ccCardBinLength)
    }),

    externalId: Yup.string().when("searchType", {
      is: (val: string) => val === "externalId",
      then: (schema) => schema.trim().required(),
    }),

    visitDate: Yup.string().required("The field is required"),
    captchaToken: Yup.string().required("Please pass captcha verification"),
    email,
  })
};
