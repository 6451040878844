import React from "react";
import styled from "styled-components";
import { ErrorType, errorMessageMaps } from "src/lib/api/types";
import { color2 } from "src/styles";
import { translate } from "src/lib/i18n";
import { MasterContext } from "src/lib/masterContext";

const ErrorMessageContainer = styled.div`
  color: ${color2.RED};
  text-align: center;
  margin: 0 auto;
`;
const EmailSupport = styled.div`
  color: ${color2.GRAY6};
`;

interface ErrorMessageProps {
  errorType?: ErrorType;
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorType, message }) => {
  const { appConfig } = React.useContext(MasterContext);

  const getErrorMessage = () =>
    message
      ? message
      : errorType
      ? errorMessageMaps[errorType] ?? "An unknown error occurred"
      : "An unknown error occurred";

  const errorReachOutSupportMsg =
    appConfig.messages.errorReachOutSupportMsg ??
    translate("messageFallbacks.errorReachOutSupportMsg");

  return errorType ? (
    <ErrorMessageContainer>
      {getErrorMessage()}
      <br />
      <EmailSupport>
        <span
          dangerouslySetInnerHTML={{ __html: errorReachOutSupportMsg }}
        ></span>
      </EmailSupport>
    </ErrorMessageContainer>
  ) : null;
};

export default ErrorMessage;
