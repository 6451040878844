import React from 'react'
import DownloadIcon from './svg/download.svg'
import ApplePayIcon from './svg/applepay.svg'
import GooglePayIcon from './svg/googlepay.svg'
import InfoIcon from './svg/info.svg'

type IconType =
  | 'DownloadIcon'
  | 'ApplePayIcon'
  | 'GooglePayIcon'
  | 'InfoIcon'

const Icons: Record<IconType, any> = {
  DownloadIcon: DownloadIcon,
  ApplePayIcon: ApplePayIcon,
  GooglePayIcon: GooglePayIcon,
  InfoIcon: InfoIcon,
}

export interface IconProps extends React.HTMLAttributes<HTMLImageElement> {
  icon: IconType
  width?: number
  height?: number
}

export const Icon: React.FC<IconProps> = ({ icon, ...props }) => {
  return <img alt={icon} src={Icons[icon]} {...props} />
}
