import { Flavor } from "./flavor";
import { LogoHP } from "src/components/LogoHP";
import { color2 } from "../../styles";

class HPFlavor extends Flavor {
  getMainColor() {
    return color2.BLUE_AIFI;
  }
  
  getCircleLogo() {
    return <LogoHP />;
  }
}

const Singleton = new HPFlavor();
export default Singleton;
