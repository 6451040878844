import { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { getDefaultAppConfig } from "./../flavors/flavorTypes";
import { setShopData } from "../api/storage";
import { Flavor } from "../flavors";
import { getFlavor } from "../flavors/utils";
import { IMasterContext } from "../masterContext";
import api from "../../lib/api/api";
import { setLocale } from "../i18n";
import { AppConfig, IFeatureFlags } from "../../../../shared/interface";

export const useMasterContext = (flavorName: string): IMasterContext => {
  const flavClass = getFlavor(flavorName);
  const [flavor, setFlavor] = useState<Flavor>(flavClass);
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlags>(
    flavClass.getFeatureFlags()
  );
  const [appConfig, setAppConfig] = useState<AppConfig>(() => getDefaultAppConfig());

  const setStoreData = (storeData: StoreData) =>
    setShopData(JSON.stringify(storeData));

  const fetchAndSetAppConfig = useCallback(async () => {
    try {
      const {
        appConfig,
        remoteFeatureFlags,
      }: { appConfig: AppConfig; remoteFeatureFlags: IFeatureFlags } =
        await api.getConfig();
      if (appConfig) {
        setAppConfig(appConfig);
        setLocale(appConfig.locale);
      }
      if (remoteFeatureFlags) {
        // enable setting feature flags in WebReceipt but easily overwrite them when they become supported in OASIS API
        const appConfigFeatureFlags = R.pick(["hideCardBrand", "hideTaxBreakdown"], appConfig);
        setFeatureFlags((featureFlags) => ({
          ...featureFlags,
          ...appConfigFeatureFlags,
          ...remoteFeatureFlags,
        }));
      }
    } catch (error) {
      console.error("Failed to load config, using default config");
    }
  }, []);

  useEffect(() => {
    fetchAndSetAppConfig();
  }, [fetchAndSetAppConfig]);

  return {
    setStoreData,
    flavor,
    setFlavor,
    featureFlags,
    appConfig,
    setAppConfig,
  };
};
