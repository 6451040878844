import React, { CSSProperties } from "react";
import { BothSideTextView } from "../BothSideTextView";
import ReceiptLabel from "../ReceiptLabel";
import { receiptLabelType } from "../ReceiptLabel/styles";
import { translate } from "../../../lib/i18n";
import { dpToPx } from "../../../styles";

export type DetailItem = {
  title: string;
  value?: string | null;
  rightView?: React.ReactNode;
  valueTall?: boolean;
  leftViewStyle?: CSSProperties
  rightViewStyle?: CSSProperties
  status?: ApiOrderLineStatus;
};

type DetailSectionProps = {
  data: DetailItem[];
  noAmounts?: boolean;
};

const getMiddleView = (status: ApiOrderLineStatus) => {
  switch (status) {
    case "contested":
      return (
        <ReceiptLabel
          receiptLabelType={receiptLabelType.CONTESTED}
          title={translate("receiptsScreen.label.contested")}
        />
      );
    case "reviewed":
      return (
        <ReceiptLabel
          receiptLabelType={receiptLabelType.REVIEWED}
          title={translate("receiptsScreen.label.reviewed")}
        />
      );
    default:
      return null;
  }
};

const ReceiptDetailSection: React.FC<DetailSectionProps> = ({
  data,
  noAmounts = false,
}: DetailSectionProps) => {
  return (
    <>
      {data.map(
        (
          {
            title,
            value,
            rightView,
            valueTall,
            status,
            leftViewStyle,
            rightViewStyle,
          },
          index
        ) => {
          const detailTitleStyle = valueTall
            ? { fontSize: dpToPx(20) }
            : undefined;
          const middleView = getMiddleView(status!);

          return (
            <BothSideTextView
              key={`${title}${index}`}
              title={`${title}`}
              detailTitle={!noAmounts && Boolean(value) ? value : null}
              detailTitleStyle={detailTitleStyle}
              rightViewStyle={rightViewStyle}
              rightView={rightView || null}
              middleView={middleView}
              leftViewStyle={leftViewStyle}
            />
          );
      })}
    </>
  )
};

export default ReceiptDetailSection;
