import * as React from "react"
const Card: React.FC<MenuIconProps> = (props) => (
  <svg
    viewBox="0 0 71 51"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    {...props}
  >
    <path
      stroke={props.color}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M63.438 2H7.79a5.8 5.8 0 0 0-4.068 1.69A5.85 5.85 0 0 0 2 7.756V42.22a5.85 5.85 0 0 0 1.715 4.083A5.8 5.8 0 0 0 7.79 48h55.772a5.682 5.682 0 0 0 4.029-1.708 5.73 5.73 0 0 0 1.64-4.072V7.757a5.853 5.853 0 0 0-1.725-4.068A5.802 5.802 0 0 0 63.437 2ZM8.684 26.77h7.47"
    />
    <path
      stroke={props.color}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M17.727 16.566a1.157 1.157 0 0 1-1.161 1.16H9.844a1.159 1.159 0 0 1-1.16-1.16V9.844a1.16 1.16 0 0 1 1.16-1.16h6.722a1.158 1.158 0 0 1 1.16 1.16v6.722ZM62.547 38.367a4.19 4.19 0 0 1-.308 1.58 4.132 4.132 0 0 1-.878 1.34 4.047 4.047 0 0 1-1.316.895 3.988 3.988 0 0 1-1.552.314 3.889 3.889 0 0 1-1.53-.304 3.946 3.946 0 0 1-1.296-.884c-.37.38-.81.68-1.296.884a3.888 3.888 0 0 1-1.531.304 4.017 4.017 0 0 1-2.866-1.21 4.167 4.167 0 0 1-1.188-2.919c0-1.094.427-2.144 1.188-2.918a4.017 4.017 0 0 1 4.397-.907c.486.204.926.505 1.296.885.37-.38.81-.68 1.295-.885a3.874 3.874 0 0 1 1.531-.303c.533 0 1.06.106 1.552.314.492.207.94.511 1.316.895.376.383.675.838.878 1.34.204.5.308 1.037.308 1.58ZM8.684 38.17H29.52M35.419 26.77h7.077M48.393 26.77h7.47"
    />
  </svg>
)
export default Card
