import React from "react";
import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";


export const PoweredByAiFiBlock = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <PoweredByAiFi />
    </div>
  );
};
