import React from "react";
import { Text, TouchableOpacity, View, ImageBackground } from "react-native";
import { styles } from "./styles";
import { ProductAdd } from "src/assets/icons/ProductAdd";
import { color2 } from "src/styles";
import { IUseFlavor, withFlavor } from "src/lib/hooks/useFlavor";

type IProductItem = IUseFlavor & {
  item: ApiProductsDetail;
  onAdd: (product: ApiProductsDetail) => void;
};

const ProductItem: React.FC<IProductItem> = ({ item, onAdd, flavor }) => {
  const css = styles(flavor);

  return (
    <View style={css.wrapper}>
      <ImageBackground
        style={css.image}
        resizeMode="contain"
        source={{ uri: item.thumbnail }}
      />
      <View style={css.column}>
        <Text style={css.title}>{item.name}</Text>
      </View>
      <TouchableOpacity style={css.addWrapper} onPress={() => onAdd(item)}>
        <ProductAdd color={color2.GRAY4} />
      </TouchableOpacity>
    </View>
  );
};

export default withFlavor(ProductItem);
