import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoMSFT } from "src/components/LogoMSFT";

class MSFTFlavor extends Flavor {
  getMainColor() {
    return color2.MSFT_MAIN;
  }

  getDashLineColor() {
    return color2.MSFT_MAIN;
  }

  getCircleLogo() {
    return <LogoMSFT />;
  }
}

const Singleton = new MSFTFlavor();
export default Singleton;
