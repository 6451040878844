import React from "react";
import {
  TouchableOpacity,
  Text,
  ActivityIndicator,
  TouchableOpacityProps,
  StyleProp,
  ViewStyle,
} from "react-native";
import { styles } from "./style";
import {
  IUseFlavor,
  withFlavor,
} from "../../../lib/hooks/useFlavor";

type ButtonType = "primary" | "ghost";

type IButtonProps = TouchableOpacityProps &
  IUseFlavor & {
    title?: string;
    children?: React.ReactNode;
    titleStyle?: StyleProp<ViewStyle>;
    loadingWhenDisabled?: boolean;
    type?: ButtonType;
  };

export const ButtonEl: React.FC<IButtonProps> = ({
  title,
  children,
  style,
  titleStyle,
  loadingWhenDisabled,
  type = "primary",
  disabled,
  flavor,
  ...props
}: IButtonProps) => {
  const css = styles(flavor.getMainColor());
  const contentStyle = [
    css.wrapper,
    style,
    type === "ghost" && css.ghost,
    disabled && css.disable,
  ];
  const buttonTitleStyle = [
    css.title,
    type === "ghost" ? css.titleGhost : null,
    titleStyle,
  ];
  const showLoading = loadingWhenDisabled && disabled;
  return (
    <TouchableOpacity style={contentStyle} disabled={disabled} {...props}>
      {showLoading && <ActivityIndicator color="#fff" />}
      {!showLoading &&
        (children || <Text style={buttonTitleStyle}>{title}</Text>)}
    </TouchableOpacity>
  );
};

export const Button = withFlavor(ButtonEl);
