import React from "react";
import { View, Text } from "react-native";
import { styles } from "./styles";
import StyledScreen from "src/components/StyledScreen";
import { CheckOkIcon } from "src/assets/icons/CheckOk";
import { Button } from "src/components/v2/Button";
import { IUseFlavor, withFlavor } from "src/lib/hooks/useFlavor";
import { translate } from "src/lib/i18n";
import { config } from "src/lib/config";

type ReceiptDetailComplainConfirmationProps = IUseFlavor & {
  onBack: () => void;
}

const ReceiptDetailComplainConfirmation: React.FC<
  ReceiptDetailComplainConfirmationProps
> = ({ flavor, onBack }) => {
  const css = styles(flavor);
  const confirmationTitle = translate("receiptDetailComplain.confirmTitle");
  const confirmationSubtitle = config.messages.contestConfirmation;
  const confirmationButtonTitle = translate(
    "receiptDetailComplain.confirmButton"
  );
  return (
    <StyledScreen>
      <View style={css.wrapper}>
        <Text style={css.title}>{confirmationTitle}</Text>
        <Text style={css.subtitle}>{confirmationSubtitle}</Text>
        <View style={css.content}>
          <CheckOkIcon color={flavor.getIconColor()} />
        </View>
        <Button
          title={confirmationButtonTitle}
          onPress={() => {
            onBack();
          }}
        />
      </View>
    </StyledScreen>
  );
};

export default withFlavor(ReceiptDetailComplainConfirmation);
