import React from "react";
import { cssStyles } from "../ReceiptHeader/styles";
import { ReceiptLogo } from "src/components/ReceiptLogo";

export function ReceiptLogoBlock() {
  return (
    <div style={cssStyles.logoWrapper}>
      <ReceiptLogo />
    </div>
  );
}
