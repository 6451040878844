import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoCoconut } from "src/components/LogoCoconut";

class CoconutFlavor extends Flavor {
  getMainColor() {
    return color2.COCONUT_GREEN;
  }

  getDashLineColor() {
    return color2.COCONUT_GREEN;
  }

  getCircleLogo() {
    return <LogoCoconut />;
  }
}

const Singleton = new CoconutFlavor();
export default Singleton;
