import React from 'react'
import { Flavor } from './flavor'
import { LogoMercedesBenzStadium } from 'src/components/LogoMercedesBenzStadium'
import { color2 } from "src/styles";
import { timezoneDate } from '../utils';
import { IFeatureFlags } from '../../../../shared/interface';

class MercedesBenzFlavor extends Flavor {
  getMainColor() {
    return color2.MERCEDESBENZ_MAIN
  }

  getFeatureFlags(): IFeatureFlags {
    return {
      ...super.getFeatureFlags(),
    }
  }

  getDashLineColor() {
    return color2.MERCEDESBENZ_MAIN
  }

  getCircleLogo() {
    return <LogoMercedesBenzStadium />
  }

  formatDateTime(dateTime: string, timezone: string) {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "MM-DD-YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  formatTableDateTime(dateTime: string, timezone: string): { date: string; time: string } {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "M/DD/YY";
    const formatTime = "hh:mma";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }
}

const Singleton = new MercedesBenzFlavor()
export default Singleton
