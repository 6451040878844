import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoKansasCityChiefs } from "src/components/LogoKansasCityChiefs";

export class KansasCityChiefs extends Flavor {
  getMainColor() {
    return color2.KANSASCITYCHIEFS_RED;
  }

  getDashLineColor() {
    return color2.KANSASCITYCHIEFS_RED;
  }

  getCircleLogo() {
    return <LogoKansasCityChiefs />;
  }
}

const Singleton = new KansasCityChiefs();
export default Singleton;
