import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoAcrisureArena } from "../../components/LogoAcrisureArena";

class AcrisureArenaFlavor extends Flavor {
  getCircleLogo() {
    return <LogoAcrisureArena />;
  }
  getMainColor() {
    return color2.DG_MAIN;
  }
}

const Singleton = new AcrisureArenaFlavor();
export default Singleton;
