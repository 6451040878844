import React, { Dispatch, SetStateAction } from "react";
import { ReceiptInfo } from "src/views/ReceiptsForm/types";
import { RequestStatus } from "../api/types";

export interface IReceiptsContext {
  receipts: ReceiptInfo[] | null;
  receiptsStatus: RequestStatus;
  setReceipts: Dispatch<SetStateAction<ReceiptInfo[] | null>>;
  setReceiptsStatus: Dispatch<SetStateAction<RequestStatus>>;
}

export const defaultReceiptsContext: IReceiptsContext = {
  receipts: null,
  receiptsStatus: "idle",
  setReceipts: () => {
    throw new Error("Not Implemented");
  },
  setReceiptsStatus: () => {
    throw new Error("Not Implemented");
  },
};

export const ReceiptsContext = React.createContext<IReceiptsContext>(
  defaultReceiptsContext
);
