import { StyleSheet } from "react-native";
import { baseStyles } from "../../text/BaseText";
import { Flavor } from "src/lib/flavors";
import { dpToPx } from "src/styles";

export const styles = (flavor: Flavor) =>
  StyleSheet.create({
    wrapper: {
      width: "100%",
      flexDirection: "row",
      paddingVertical: dpToPx(16),
      paddingHorizontal: dpToPx(24),
      alignItems: "center",
    },
    image: {
      width: dpToPx(64),
      height: dpToPx(64),
      marginRight: dpToPx(16),
    },
    title: {
      ...baseStyles(flavor).titleText,
    },
    addWrapper: {
      paddingLeft: dpToPx(24),
    },
    column: {
      flex: 1,
      flexDirection: "column",
    },
  });
