import { Flavor } from "./flavor";
import { LogoJCA } from "../../components/LogoJCA";
import { timezoneDate } from "../utils";
import { IFeatureFlags } from "../../../../shared/interface";

class JCAFlavor extends Flavor {
  getCircleLogo() {
    return <LogoJCA />;
  }

  getMainColor() {
    return "#e11a21";
  }

  getFeatureFlags(): IFeatureFlags {
    return {
      ...super.getFeatureFlags(),
      hidePoweredByAiFi: true,
    };
  }

  formatDateTime(dateTime: string, timezone: string) {
    const date = timezoneDate(dateTime, timezone);
    const formatDate = "DD-MM-YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  formatTableDateTime(
    dateTime: string,
    timezone: string
  ): { date: string; time: string } {
    const date = timezoneDate(dateTime, timezone);
    const formatDate = "DD-MM-YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }
}

const Singleton = new JCAFlavor();
export default Singleton;
