import { Text, View } from "react-native";
import { useReceiptOrder } from "../../ReceiptContext";
import { styles } from "../ReceiptFooter/styles";
import { TemplatedOrderText } from "../../components/TemplatedOrderText";
import { MasterContext } from "src/lib/masterContext";
import { useContext } from "react";


export const FooterTextBlock = () => {
  const {
    appConfig: { messages },
  } = useContext(MasterContext);

  const order = useReceiptOrder();

  let footerText: string | undefined = messages.footerPaid;
  if (order.status === "contested") {
    footerText = messages.footerInReview;
  } else if (order.status === "reviewed") {
    footerText = messages.footerReviewed;
  }

  return footerText ?
    <View style={styles.addressWrapper}>
      <Text style={styles.address}>
        <TemplatedOrderText template={footerText}/>
      </Text>
    </View>
  : null;
};
