import React from "react";
import { Text, TouchableOpacity, View, ViewProps } from "react-native";

import {
  IUseFlavor,
  withFlavor,
} from "../../../lib/hooks/useFlavor";
import { styles } from "./style";

type IQuantityProps = ViewProps &
  IUseFlavor & {
    quantity: number;
    max?: number;
    trailingText?: string;
    children?: React.ReactNode;
    onChange: (value: number) => void;
  };

const QuantityButtonEl: React.FC<IQuantityProps> = ({
  quantity,
  onChange,
  max = 10000,
  trailingText,
  flavor,
  ...props
}: IQuantityProps) => {
  const css = styles(flavor);

  const onDecrease = () => {
    onChange && onChange(quantity - 1);
  };
  const onIncrease = () => {
    onChange && onChange(quantity + 1);
  };
  const leftButtonStyle = [css.button, !quantity ? css.disable : null];
  const rightButtonStyle = [css.button, quantity >= max ? css.disable : null];

  return (
    <View style={[css.wrapper, props.style]}>
      <TouchableOpacity
        disabled={!quantity}
        style={leftButtonStyle}
        onPress={onDecrease}
      >
        <Text style={css.text}>-</Text>
      </TouchableOpacity>
      <Text style={css.quantity}>{`${quantity}`}</Text>
      <TouchableOpacity
        disabled={quantity >= max}
        style={rightButtonStyle}
        onPress={onIncrease}
      >
        <Text style={css.text}>+</Text>
      </TouchableOpacity>
      {trailingText && <Text style={css.trail}>{trailingText}</Text>}
      {props.children}
    </View>
  );
};

export const QuantityButton = withFlavor(QuantityButtonEl);
