import React from "react";
import { View, Image, TextInputProps, TextInput } from "react-native";
import { styles } from "./style";

interface IInput extends TextInputProps {
  isSearch?: boolean;
  border?: boolean;
  maxLength?: number;
}

export const Input: React.FC<IInput> = ({
  maxLength,
  border = true,
  isSearch,
  ...props
}) => {
  const wrapper = [
    styles.wrapper,
    isSearch ? styles.search : null,
    props.multiline ? styles.multiInput : null,
  ];
  const inputStyle = [
    styles.input,
    border && !isSearch ? styles.borderStyle : null,
  ];
  return (
    <View style={wrapper}>
      {isSearch && (
        <Image
          source={require("src/components/v2/resource/img/search@2x.png")}
        />
      )}
      <TextInput
        maxLength={maxLength}
        style={inputStyle}
        {...props}
        underlineColorAndroid="rgba(0,0,0,0)"
      />
    </View>
  );
};
