import React, { FC, useState } from "react";

import { IReceiptsContext, ReceiptsContext } from "./index";
import { ReceiptInfo } from "src/views/ReceiptsForm/types";
import { RequestStatus } from "../api/types";

export const ReceiptsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [receipts, setReceipts] = useState<ReceiptInfo[] | null>(null);
  const [receiptsStatus, setReceiptsStatus] = useState<RequestStatus>("idle");

  const receiptsContext: IReceiptsContext = {
    receipts,
    setReceipts,
    receiptsStatus,
    setReceiptsStatus,
  };

  return (
    <ReceiptsContext.Provider value={receiptsContext}>
      {children}
    </ReceiptsContext.Provider>
  );
};
