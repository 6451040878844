import React, { ComponentType, useContext } from "react";
import { IFlavor } from "../flavors/flavorTypes";
import { MasterContext } from "../masterContext";

export interface IUseFlavor {
  flavor: IFlavor;
}

export function withFlavor<TProps extends IUseFlavor>(WrappedComponent: ComponentType<TProps>) {
  return (hocProps: Omit<TProps, 'flavor'>) => {
    const { flavor } = useContext(MasterContext);
    return (
      <WrappedComponent
        {
          ...hocProps as TProps // lesser evil, as we get nice type for return type of `withFlavor`
        }
        flavor={flavor}
      />
    )
  };
};
