import React from "react";
import { TextInput, View } from "react-native";
import { styles } from "./styles";
import { color2 } from "src/styles";
import { Search } from "src/assets/icons/Search";
import { translate } from "src/lib/i18n";
import { IUseFlavor, withFlavor } from "src/lib/hooks/useFlavor";

type IProductSearch = IUseFlavor & {
  onChange: (search: string) => void;
};

const ProductSearch: React.FC<IProductSearch> = ({ onChange, flavor }) => {
  const [search, setSearch] = React.useState("");
  const css = styles(flavor);

  const onChangeText = (change: string) => {
    setSearch(change);
    onChange(change);
  };

  return (
    <View style={css.wrapper}>
      <Search color={color2.GRAY4} />
      <View style={css.wrapperInput}>
        <TextInput
          placeholder={translate("slideModal.searchPlaceholder")}
          value={search}
          onChangeText={onChangeText}
          style={css.input}
        />
      </View>
    </View>
  );
};

export default withFlavor(ProductSearch);
