import React from "react";
import * as Handlebars from 'handlebars';
import { useReceiptOrder } from "../ReceiptContext";
import * as R from "ramda";
import styled from 'styled-components'
import { font } from "src/styles/themes";

export type TemplatedOrderTextProps = {
  className?: string
  template: string;
};

export const TemplatedOrderText = (props: TemplatedOrderTextProps) => {
  const order = useReceiptOrder()

  const { template, className } = props;
  if (!template) {
    return null;
  }

  const renderTemplate = Handlebars.compile(
    template.replaceAll(/(\r\n|\n)/g, '<br>')
  );

  const renderedHtml = renderTemplate({
    order: R.pick(["id", "currencyCode"], order),
    store: order.store
      ? R.pick(
          [
            "id",
            "displayableName",
            "addressLine1",
            "addressCity",
            "addressRegion",
            "addressCountry",
            "addressPostalCode",
          ],
          order.store
        )
      : {},
  });

  return <Templated className={className} dangerouslySetInnerHTML={{ __html: renderedHtml }} />
};


export const Templated = styled.div`
  font-family: ${font.family.montserrat};
  font-size: ${font.size.subtitleText}px;
  font-weight: 500;
  color: #000000;
  padding: 0 8px;
  line-height: 1.1em;
`