import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoLCFC } from "src/components/LogoLCFC";

class LCFCFlavor extends Flavor {
  getMainColor() {
    return color2.LEICESTER_CITY;
  }

  getDashLineColor() {
    return color2.LEICESTER_CITY;
  }

  getCircleLogo() {
    return <LogoLCFC />;
  }
}

const Singleton = new LCFCFlavor();
export default Singleton;
