import React, { useContext, useEffect, useRef } from "react";
import {
  View,
  Animated,
  ViewProps,
  Text,
  TouchableOpacity,
} from "react-native";
import { styles } from "./style";
import { MasterContext } from "src/lib/masterContext";

interface ISlideModalProps {
  style?: ViewProps;
  headChildren?: React.ReactNode;
  dismissCallback?: () => void;
  contentHeight: number;
  closeText: string;
  children?: React.ReactNode
}

export const SlideModal: React.FC<ISlideModalProps> = ({
  style,
  headChildren,
  dismissCallback,
  closeText,
  children,
  contentHeight,
}) => {
  const { flavor } = useContext(MasterContext);
  const backgroundOpacity = useRef(new Animated.Value(0)).current;
  const contentOffset = useRef(new Animated.Value(0)).current;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showAnimation = () => {
    Animated.parallel([
      Animated.spring(contentOffset, {
        toValue: -contentHeight,
        friction: 6,
        tension: 9,
        useNativeDriver: true,
      }),
      Animated.timing(backgroundOpacity, {
        toValue: 0.7,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const hideAnimation = (callback?: () => void) => {
    Animated.parallel([
      Animated.spring(contentOffset, {
        toValue: 0,
        useNativeDriver: true,
      }),
      Animated.timing(backgroundOpacity, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start(() => callback && callback());
  };

  useEffect(() => {
    showAnimation();
  }, [showAnimation]);

  const onClose = () => {
    hideAnimation(dismissCallback);
  };
  const css = styles(flavor);

  return (
    <View style={[css.wrapper, style]}>
      <Animated.View
        style={[css.background, { opacity: backgroundOpacity }]}
      ></Animated.View>
      <Animated.View
        style={[
          css.content,
          {
            transform: [
              {
                translateY: -contentHeight,
              },
            ],
            height: contentHeight,
          },
        ]}
      >
        <View>
          <TouchableOpacity style={css.topIndicator} onPress={onClose} />
          <View style={css.header}>
            {headChildren}
            <Text style={css.close} onPress={onClose}>
              {closeText}
            </Text>
          </View>
        </View>
        {children}
      </Animated.View>
    </View>
  );
};
