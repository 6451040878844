import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoSodexoSanofi } from "../../components/LogoSodexoSanofi";

class SodexoSanofiFlavor extends Flavor {
  getCircleLogo() {
    return <LogoSodexoSanofi />;
  }
  getMainColor() {
    return color2.DG_MAIN;
  }
}

const Singleton = new SodexoSanofiFlavor();
export default Singleton;
