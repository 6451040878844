import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoMorrisons } from "src/components/LogoMorrisons";

class MorrisonsFlavor extends Flavor {
  getMainColor() {
    return color2.MORRISONS;
  }

  getCircleLogo() {
    return <LogoMorrisons />;
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      isAddressEnabled: true,
      hideProcessTime: true,
      aifiFooterText: false,
    };
  }
}

const Singleton = new MorrisonsFlavor();
export default Singleton;
