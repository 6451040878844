import { LogoGoldenStateWarriors } from "src/components/LogoGoldenStateWarriors";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class GoldenStateWarriorsFlavor extends Flavor {
  getMainColor() {
    return color2.GOLDEN_STATE_WARRIORS;
  }

  getDashLineColor() {
    return color2.GOLDEN_STATE_WARRIORS;
  }

  getCircleLogo() {
    return <LogoGoldenStateWarriors />;
  }
}

const Singleton = new GoldenStateWarriorsFlavor();
export default Singleton;
