import React from "react";

export const LogoClippers: React.FC = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="786.000000pt"
    height="462.000000pt"
    viewBox="0 0 786.000000 462.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,462.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M7852 4590 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path
        d="M3540 3993 c-25 -1 -112 -8 -195 -13 -82 -6 -179 -15 -215 -20 -36
   -4 -105 -14 -155 -20 -1014 -131 -1845 -508 -2167 -983 -128 -189 -176 -407
   -130 -598 26 -109 57 -178 128 -286 106 -163 298 -332 549 -482 195 -117 413
   -212 690 -300 882 -282 2037 -334 3035 -136 295 59 820 212 903 264 18 11 97
   54 177 96 300 159 508 318 678 518 12 15 22 32 22 37 0 6 5 10 10 10 6 0 10 4
   10 9 0 5 16 36 35 68 54 91 84 186 91 288 5 77 3 104 -20 192 -35 138 -65 209
   -130 307 -235 353 -702 634 -1396 840 -82 24 -215 57 -310 76 -25 5 -86 18
   -136 29 -195 42 -539 84 -819 101 -155 9 -546 11 -655 3z m-1775 -1335 c0
   -246 3 -449 6 -453 4 -3 274 151 600 343 327 191 671 393 764 448 l170 99 420
   3 c444 3 484 0 507 -41 10 -17 14 -145 18 -524 l5 -503 200 127 c110 69 382
   242 605 383 747 474 825 523 865 541 34 16 68 19 233 19 205 0 249 -8 271 -48
   7 -14 11 -144 11 -389 0 -421 -1 -426 -81 -480 -68 -45 -447 -263 -458 -263
   -8 0 -11 126 -11 400 0 220 -4 400 -9 400 -8 0 -453 -277 -971 -604 -129 -82
   -249 -155 -267 -162 -26 -11 -122 -14 -457 -14 l-425 0 -28 24 -28 24 -5 506
   -5 505 -394 -229 c-216 -126 -399 -234 -405 -240 -8 -8 16 -37 89 -108 239
   -230 475 -466 475 -474 0 -14 -551 -9 -581 5 -15 7 -104 86 -198 177 -94 91
   -175 166 -179 168 -5 1 -146 -77 -313 -175 l-304 -178 -295 -3 c-162 -2 -305
   0 -318 3 -12 3 -31 20 -42 38 -19 30 -20 52 -20 387 0 323 2 359 19 395 10 22
   27 47 37 56 10 9 113 72 229 139 115 67 219 128 230 136 11 8 24 13 30 11 7
   -3 10 -156 10 -449z"
      />
    </g>
  </svg>
);
