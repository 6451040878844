import { ITranslation } from "./types";

export const da: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      "Har du lige forladt butikken? Din kvittering er under behandling, så kig tilbage om lidt tid. I tilfælde af problemer kan du kontakte vores kundeservice.",
    errorReachOutSupportMsg:
      "I tilfælde af problemer kan du kontakte vores kundeservice.",
  },
  api: {
    E_FORCED_LOGOUT: "Du er logget ud, da din forbindelse er udløbet.",
  },
  orderStatus: {
    draft: "Kladde",
    contested: "Anfægtet",
    paid: "Betalt",
    unpaid: "Ikke betalt",
    pending: "Afventer",
    reviewed: "Rettet",
  },
  receiptsList: {
    headings: {
      date: "Dato",
      time: "Tidspunkt",
      status: "Status",
      total: "Total",
    },
    action: {
      view: "Vis",
    },
    statusTooltip: {
      draft: "Kladde - Ordren behandles af systemet",
      paid: "Betalt - Ordren er behandlet og betalt",
      paymentProcessing:
        "Betaling i gang - Ordren er behandlet og betalingen undervejs",
      unpaid: "Ikke betalt - Ordren er behandlet, men betalingen mislykkedes",
      pending:
        "Afventer - Kunde har anfægtet Ordren, som afventer behandling fra butikken",
      reviewed:
        "Rettet - Kunden har anfægtet Ordren, og butikken har gennemført en rettelse",
    },
  },
  receiptDetailScreen: {
    headerTitle: "Kvitteringsdetaljer",
    subtotal: "Subtotal",
    taxBreakdown: {
      headers: {
        rate: "Moms %",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Moms",
        total: "Total:",
      },
    },
    tax: "Moms",
    taxCRV: "Pant",
    total: "Total",
    processTime: "Behandlingstid",
    date: "Dato",
    paymentStatus: "Status for betaling",
    orderId: "Ordre-ID",
    listOfProducts: "Varer",
  },
  receiptDetailComplain: {
    reportProblem: "Rapportér et problem med denne kvittering",
    submitReview: "Indsend til gennemgang",
    confirmTitle: "Kvittering sendt",
    confirmButton: "Færdig",
    addMissingItem: "Tilføj manglende vare",
    addMissingItemMessage: "Tilføj en besked (valgfri)",
  },
  receiptsScreen: {
    label: {
      contested: "ANFÆGTET",
      draft: "KLADDE",
      empty: "TOM",
      paid: "BETALT",
      pending: "KLADDE",
      refunded: "REFUNDERET",
      reviewed: "RETTET",
      unknown: "Ukendt",
      unpaid: "IKKE BETALT",
    },
    message: {
      contested: "Anfægtet besked",
      draft: "Kladde besked",
      empty: "Tom besked",
      paid: "Betalt besked",
      pending: "Afventer besked",
      refunded: "Refunderet besked",
      reviewed: "Rettet besked",
      unpaid: "Ikke betalt besked",
    },
    minutes: "minutter",
    hours: "timer",
    days: "dage",
  },
  slideModal: {
    close: "Luk",
    searchPlaceholder: "Søg efter vare",
  },
  receiptsHome: {
    receiptPortal: "Digital kvittering",
    selectPaymentType: "Vælg betalingsmiddel for at finde din kvittering",
    cardBin: "Første %{cardBinDigitsCount} cifre af kortnummer",
    last4: "Sidste fire cifre af kortnummer",
    last4Apple: "Sidste fire cifre af Apple Pay-kortnummer",
    last4Google: "Sidste fire cifre af Google Pay-kortnummer",
    instructionApple:
      "Find de sidste fire cifre af Apple Pay-kortnummeret i din Apple Wallet",
    instructionGoogle:
      "Find de sidste fire cifre af Google Pay-kortnummeret i din Wallet",
    instructionExternalId:
      "Find nummer på betalingsreference i din betalings-app eller dit kontoudtog.",
    externalIdLabel: "Betalingsreference",
    externalIdPlaceholder: "",
    expDate: "Udløbsdato",
    visitDate: "Dato for køb",
    submit: "Hent kvittering(er)",
    registerEmail: "Skriv din email for at abonnere på fremtidige kvitteringer",
    registerEmailSuccess: "Email blev opdateret korrekt.",
    registerEmailFail:
      "Email blev ikke opdateret, da der ikke var nogen kvitteringer for indtastet betalingsmiddel og/eller købsdato.",
    unrecognizedError: "Der opstod en ukendt fejl",
    inconclusiveCardInfo: "Vi kan ikke finde din kvittering",
    invalidCaptcha: "Verificering mislykkedes - prøv igen",
    oasisApiError:
      "Ingen forbindelse til kvitteringsserveren. Prøv igen senere.",
  },
};
