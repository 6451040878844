import { useContext } from "react";
import { MasterContext } from "src/lib/masterContext";
import ReceiptDetailSection, {
  DetailItem,
} from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { useReceiptOrder } from "../../ReceiptContext";

export function OrderDateTimeBlock() {
  const order = useReceiptOrder();
  const {
    appConfig: { timezone },
    flavor,
  } = useContext(MasterContext);

  const created = flavor.formatDateTime(order.draftCreatedAt, timezone);

  const dateAndTimeData = [
    {
      title: translate("receiptDetailScreen.date"),
      value: `${created.date} ${created.time}`,
    },
  ] as DetailItem[];

  return <ReceiptDetailSection data={dateAndTimeData} />;
}
