import { createMachine } from "xstate";

export const navigationMachine = createMachine({
  id: "navigation",
  initial: "detail",
  states: {
    detail: {
      on: { CONTEST: "contest" },
    },
    contest: {
      on: { BACK: "detail", CONTEST_MESSAGE: "contestMsg" },
    },
    contestMsg: {
      on: { BACK: "detail" },
    },
  },
});
