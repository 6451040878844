import { LogoMSFT } from "src/components/LogoMSFTSingapore";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class MSFTSingaporeFlavor extends Flavor {
  getMainColor() {
    return color2.MSFT_MAIN;
  }

  getDashLineColor() {
    return color2.MSFT_MAIN;
  }

  getCircleLogo() {
    return <LogoMSFT />;
  }
}

const Singleton = new MSFTSingaporeFlavor();
export default Singleton;
