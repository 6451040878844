import { useContext } from "react";
import { ActivityIndicator } from "react-native";
import { MasterContext } from "src/lib/masterContext";
import { Button } from "../../../../components/v2/Button";
import { translate } from "../../../../lib/i18n";
import { DownloadReceiptBlock } from "../blocks/DownloadReceiptBlock";
import { PoweredByAiFiBlock } from "../blocks/PoweredByAiFiBlock";
import { styles } from "./styles";
import { FooterTextBlock } from "../blocks/FooterTextBlock";
import { FooterOrderIdBlock } from "../blocks/FooterOrderIdBlock";

interface IReceiptFooter {
  showSubmit: boolean;
  onSubmit: () => void;
  submitText?: string;
  loading?: boolean;
  isSubmitDisabled?: boolean;
}

const ReceiptFooter = ({
  onSubmit,
  showSubmit,
  submitText,
  loading = false,
  isSubmitDisabled,
}: IReceiptFooter) => {
  const {
    flavor,
    featureFlags: { downloadReceiptEnabled, hidePoweredByAiFi },
  } = useContext(MasterContext);

  const title = submitText || translate("receiptDetailComplain.reportProblem");

  const submit = showSubmit ? (
    loading ? (
      <ActivityIndicator color={flavor.getSpinnerColor()} />
    ) : (
      <Button
        disabled={isSubmitDisabled}
        style={styles.submitButton}
        title={title}
        onPress={onSubmit}
      />
    )
  ) : null;

  return (
    <>
      <FooterOrderIdBlock/>
      <FooterTextBlock/>
      {downloadReceiptEnabled ? <DownloadReceiptBlock /> : null}
      {submit}
      {!hidePoweredByAiFi && <PoweredByAiFiBlock />}
    </>
  );
};

export default ReceiptFooter;


