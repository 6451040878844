import { FlexAlignType } from "react-native";

const mimicView = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  alignItems: 'center' as const,
}

export const cssStyles = {
  wrapper: {
    ...mimicView,
    width: "100%",
  },
  logoWrapper: {
    ...mimicView,
    alignItems: "center" as FlexAlignType,
    padding: "5vh",
    maxHeight: '33vh'
  },
}
