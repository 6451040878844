import { dpToPx } from "../../../../styles";

const mimicView = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  alignItems: 'center' as const,
}

export const cssStyles = {
  wrapper: {
    ...mimicView,
    alignItems: 'stretch',
    width: "100%",
  },
  logoWrapper: {
    ...mimicView,
    alignItems: "center",
    padding: dpToPx(24),
    maxHeight: '33vh'
  },
}
