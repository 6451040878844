import { ErrorType } from "./types";
import * as Yup from "yup";

interface ErrorResponse {
  type: ErrorType;
  msg: string;
}

interface ErrorResponseWithData {
  response: {
    data: ErrorResponse;
  };
}

const errorResponseSchema = Yup.object().shape({
  response: Yup.object()
    .shape({
      data: Yup.object()
        .shape({
          type: Yup.mixed().oneOf(Object.values(ErrorType)),
          msg: Yup.string().required(),
        })
        .required(),
    })
    .required(),
});

export function isErrorResponse(err: unknown): err is ErrorResponseWithData {
  return errorResponseSchema.isValidSync(err);
}
