import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoRio } from "src/components/LogoRio";

class RioFlavor extends Flavor {
  getMainColor() {
    return color2.RIO;
  }

  getCircleLogo() {
    return <LogoRio />;
  }
}

const Singleton = new RioFlavor();
export default Singleton;
