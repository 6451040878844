import React from "react";
import { BrowserRouter } from "react-router-dom";

import { MasterSettingsProvider } from "./lib/masterContext/masterSettingsProvider";
import { FlavorStyles } from "./styles/flavorStyles";
import { AppRoutes } from "./views/AppRoutes";
import { ReceiptsProvider } from "./lib/receiptsContext/receiptsProvider";

import './App.css'

export const App: React.FC = () => {
  return (
    <div className="App">
      <MasterSettingsProvider>
        <ReceiptsProvider>
          <FlavorStyles>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </FlavorStyles>
        </ReceiptsProvider>
      </MasterSettingsProvider>
    </div>
  );
};
