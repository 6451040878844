import React, { FC } from "react";

import { MasterContext } from "./index";
import { useMasterContext } from "../hooks/useMasterContext";

import { flavorName } from "../config";

export const MasterSettingsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const masterContext = useMasterContext(flavorName);

  return (
    <MasterContext.Provider value={masterContext}>
      {children}
    </MasterContext.Provider>
  );
};
