import ReceiptDetailSection from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { getTimeSpent } from "../../../../lib/utils";
import { useReceiptOrder } from "../../ReceiptContext";

export const ProcessTimeBlock = () => {
  const order = useReceiptOrder()

  const processTime = order
    ? getTimeSpent(order.draftCreatedAt, order.processedAt)
    : "";

  const dateAndTimeData = [
    {
      title: translate("receiptDetailScreen.processTime"),
      value: processTime,
    },
  ];

  return <ReceiptDetailSection data={dateAndTimeData} />;
};
