import styled from "styled-components"
import { dpToPx } from "src/styles"
import { ImageBlockSettings } from "../../../../../../web-receipt-api/src/lib/validateReceiptStructure"

export type ImageBlockProps = {
  blockSettings: ImageBlockSettings
}

export function ImageBlock(props: ImageBlockProps) {
  const { blockSettings } = props
  return (
    <StyledImage src={blockSettings.src} $maxHeightPx={dpToPx(blockSettings.maxHeightDp)} />
  )
}

const StyledImage = styled.img<{$maxHeightPx: number}>`
  object-fit: contain;
  max-height: ${({$maxHeightPx}) => $maxHeightPx}px;
`