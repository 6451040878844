import React, { useContext } from "react";
import { Icon } from "src/assets/icons/icon";
import { MasterContext } from '../../../../lib/masterContext'


export const DownloadReceiptBlock = () => {
  const {
    featureFlags: { downloadReceiptEnabled },
  } = useContext(MasterContext);

  if (!downloadReceiptEnabled) {
    return null
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Icon
        width={45}
        height={45}
        onClick={() => window.print()}
        icon="DownloadIcon" />
    </div>
  );
};
