import { StyleSheet } from 'react-native'

export const styles = (bgColor: string) =>
  StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      width: '98%',
      alignSelf: 'center',
    },
    line: {
      height: 1,
      backgroundColor: bgColor,
    },
  })
