import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoCainz } from "src/components/LogoCainz";

class CainzFlavor extends Flavor {
  getMainColor() {
    return color2.BLACK;
  }

  getDashLineColor() {
    return color2.BLACK;
  }

  getCircleLogo() {
    return <LogoCainz />;
  }
}

const Singleton = new CainzFlavor();
export default Singleton;
