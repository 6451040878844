import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoFloridaPanthers } from "src/components/LogoFloridaPanthers";

class FloridaPanthers extends Flavor {
  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getDashLineColor() {
    return color2.BLUE_AIFI;
  }

  getCircleLogo() {
    return <LogoFloridaPanthers />;
  }
}

const Singleton = new FloridaPanthers();
export default Singleton;
