import { StyleSheet, Dimensions } from "react-native";
import { color2 } from "src/styles";
import { Flavor } from "src/lib/flavors";
import { hexToRgba } from "../../../utils";

const { width, height } = Dimensions.get("window");

export const styles = (flavor: Flavor) =>
  StyleSheet.create({
    wrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      width,
      height,
      backgroundColor: hexToRgba(color2.BLACK, 0),
    },
    background: {
      backgroundColor: color2.BLACK,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height,
    },
    content: {
      position: "absolute",
      bottom: -height,
      height: height - 100,
      width: "100%",
      left: 0,
      backgroundColor: color2.WHITE,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    topIndicator: {
      backgroundColor: hexToRgba(color2.BLUE_DARK, 0.23),
      borderRadius: 4,
      height: 3,
      width: 60,
      alignSelf: "center",
      marginTop: 8,
    },
    header: {
      flexDirection: "row",
      height: 50,
      alignItems: "center",
    },
    close: {
      fontSize: 16,
      color: color2.BLUE_DARK,
      fontFamily: flavor.getPrimaryFontLight(),
      paddingRight: 24,
    },
    title: {
      fontSize: 10,
      fontWeight: "bold",
      color: color2.BLUE_AIFI,
    },
  });
