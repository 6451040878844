import { StyleSheet } from "react-native";
import { color2 } from "src/styles";

export const styles = (color: string) =>
  StyleSheet.create({
    wrapper: {
      backgroundColor: color,
      padding: 15,
      borderRadius: 12,
      alignItems: "center",
      marginLeft: 20,
      marginRight: 20,
      minHeight: 48,
    },
    disable: {
      opacity: 0.5,
    },
    ghost: {
      backgroundColor: color2.WHITE,
    },
    title: {
      color: color2.WHITE,
      fontWeight: "bold",
    },
    titleGhost: {
      color,
    },
  });
