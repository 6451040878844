import AsyncStorage from "@react-native-async-storage/async-storage";

const namespace = "io.aifi.autocheckout";
const createNamespacedKey = (key: string) => `@${namespace}:${key}`;

const keys = {
  AUTH_TOKEN: createNamespacedKey("AUTH_TOKEN"),
  SHOP_DATA: createNamespacedKey("SHOP_DATA"),
};

export const getAuthToken = () => AsyncStorage.getItem(keys.AUTH_TOKEN);

export const removeAuthToken = () => AsyncStorage.removeItem(keys.AUTH_TOKEN);

export const setAuthToken = (token: string) =>
  AsyncStorage.setItem(keys.AUTH_TOKEN, token);

export const setShopData = (value: string) =>
  AsyncStorage.setItem(keys.SHOP_DATA, value);
