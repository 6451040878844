import { useContext } from "react";
import { Button } from "../../../../components/v2/Button";
import { translate } from "../../../../lib/i18n";
import { styles as footerStylesRN } from "../ReceiptFooter/styles";
import { MasterContext } from '../../../../lib/masterContext'
import { useReceipt } from "../../ReceiptContext";

export const ReportProblemBlock = ({
  submitText, isSubmitDisabled,
}: {
  isSubmitDisabled?: boolean;
  submitText?: string;
}) => {
  const {order, onContest} = useReceipt()
  const {
    featureFlags: { contestingEnabled },
  } = useContext(MasterContext);

  const showReportProblem =
    contestingEnabled &&
    order.status === "paid" &&
    order.lineItems.filter((i) => i.rin).length === order.lineItems.length;

  if (!showReportProblem) {
    return null
  }

  const title = submitText || translate("receiptDetailComplain.reportProblem");

  return (
    <Button
      disabled={isSubmitDisabled}
      style={footerStylesRN.submitButton}
      title={title}
      onPress={onContest} />
  );
};
