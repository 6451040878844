import { translate } from "../i18n";

export type OrderDetailData = {
  orderId: string;
  isDraft: boolean;
};

export type OrdersData = {
  after: string;
  count: number;
  direction: string;
  isDraft: boolean;
};

export type RefreshTokenData = {
  accessToken: string;
};

export type IContestedReceiptItem = {
  rin: string;
  name: string;
  quantity: number;
};

export type ContestReceiptData = {
  orderId: string;
  originalReceipt: IContestedReceiptItem[];
  contestedReceipt: IContestedReceiptItem[];
};

export type ContestReceiptResponse = {
  responseCode: number;
  error?: string;
};

export type ProductData = {
  name: string;
};

export type RequestStatus = "loading" | "success" | "error" | "idle";

export enum ErrorType {
  UNRECOGNIZED_ERR = "UNRECOGNIZED_ERR",
  INCONCLUSIVE_CARD_INFO = "INCONCLUSIVE_CARD_INFO",
  INVALID_CAPTCHA = "INVALID_CAPTCHA",
  OASIS_API_ERROR = "OASIS_API_ERROR",
}

export const errorMessageMaps: Record<ErrorType, string> = {
  [ErrorType.UNRECOGNIZED_ERR]: translate("receiptsHome.unrecognizedError"),
  [ErrorType.INCONCLUSIVE_CARD_INFO]: translate(
    "receiptsHome.inconclusiveCardInfo"
  ),
  [ErrorType.INVALID_CAPTCHA]: translate("receiptsHome.invalidCaptcha"),
  [ErrorType.OASIS_API_ERROR]: translate("receiptsHome.oasisApiError"),
};
