import React from "react";
// import Svg, { Path, Rect, Polygon, G } from "react-native-svg";

export const LogoRio = () => (
  <svg width={200} height={50} viewBox="0 0 1024 256">
    <g transform="translate(0, -400)">
      <path
        fill="#D8005F"
        d="M289.3,415.3v34.2h15.8c3.6,0,6.3-0.8,8-2.4c1.7-1.6,2.8-4.8,3.4-9.5h2.4V467h-2.4c0-3.4-0.5-5.9-1.3-7.4
					c-0.8-1.6-2-2.8-3.5-3.6c-1.5-0.8-3.7-1.2-6.6-1.2h-15.8v27.3c0,4.4,0.3,7.3,0.8,8.7c0.4,1.1,1.3,2,2.7,2.8
					c1.9,1,3.8,1.5,5.9,1.5h3.1v2.4h-37.3v-2.4h3.1c3.6,0,6.2-1,7.8-3.1c1-1.4,1.5-4.7,1.5-9.9v-56.1c0-4.4-0.3-7.3-0.8-8.7
					c-0.4-1.1-1.3-2-2.6-2.8c-1.8-1-3.8-1.5-5.9-1.5h-3.1v-2.4h64.7l0.8,19.1h-2.2c-1.1-4.1-2.4-7-3.9-8.9c-1.5-1.9-3.3-3.3-5.4-4.1
					c-2.2-0.9-5.5-1.3-10-1.3H289.3z"
      />
      <path
        fill="#D8005F"
        d="M356.8,437v13.2c4.9-8.8,10-13.2,15.1-13.2c2.3,0,4.3,0.7,5.8,2.1c1.5,1.4,2.3,3.1,2.3,5
					c0,1.7-0.6,3.1-1.7,4.2c-1.1,1.2-2.4,1.7-4,1.7c-1.5,0-3.2-0.7-5-2.2c-1.9-1.5-3.2-2.2-4.1-2.2c-0.8,0-1.6,0.4-2.5,1.3
					c-1.9,1.8-3.9,4.6-6,8.7v28.1c0,3.2,0.4,5.7,1.2,7.4c0.6,1.2,1.5,2.1,2.9,2.9c1.4,0.8,3.4,1.2,6.1,1.2v2.3h-30.1v-2.3
					c3,0,5.2-0.5,6.7-1.4c1.1-0.7,1.8-1.8,2.2-3.3c0.2-0.7,0.3-2.8,0.3-6.2v-22.8c0-6.8-0.1-10.9-0.4-12.2c-0.3-1.3-0.8-2.3-1.5-2.9
					c-0.7-0.6-1.7-0.9-2.8-0.9c-1.3,0-2.8,0.3-4.5,1l-0.6-2.3l17.8-7.2H356.8z"
      />
      <path
        fill="#D8005F"
        d="M403.5,437v47.2c0,3.7,0.3,6.1,0.8,7.3c0.5,1.2,1.3,2.1,2.4,2.7c1,0.6,3,0.9,5.7,0.9v2.3h-28.5v-2.3
					c2.9,0,4.8-0.3,5.8-0.8c1-0.6,1.8-1.5,2.3-2.8c0.6-1.3,0.9-3.7,0.9-7.4v-22.6c0-6.4-0.2-10.5-0.6-12.4c-0.3-1.4-0.8-2.3-1.4-2.9
					c-0.6-0.5-1.5-0.8-2.6-0.8c-1.2,0-2.6,0.3-4.4,1l-0.9-2.3l17.7-7.2H403.5z M398.2,406.3c1.8,0,3.3,0.6,4.6,1.9
					c1.3,1.3,1.9,2.8,1.9,4.6c0,1.8-0.6,3.3-1.9,4.6c-1.3,1.3-2.8,1.9-4.6,1.9c-1.8,0-3.3-0.6-4.6-1.9c-1.3-1.3-1.9-2.8-1.9-4.6
					c0-1.8,0.6-3.3,1.9-4.6C394.8,407,396.4,406.3,398.2,406.3z"
      />
      <path
        fill="#D8005F"
        d="M429.6,460.9c0,8.7,2.1,15.6,6.3,20.5c4.3,5,9.3,7.4,15.1,7.4c3.8,0,7.2-1.1,10-3.2
					c2.8-2.1,5.2-5.7,7.1-10.9l2,1.3c-0.9,5.9-3.5,11.2-7.8,16c-4.3,4.8-9.7,7.2-16.2,7.2c-7,0-13.1-2.7-18.1-8.2
					c-5-5.5-7.5-12.9-7.5-22.1c0-10,2.6-17.9,7.7-23.5c5.1-5.6,11.6-8.4,19.4-8.4c6.6,0,12,2.2,16.2,6.5c4.2,4.3,6.3,10.1,6.3,17.4
					H429.6z M429.6,457.2h27.2c-0.2-3.8-0.7-6.4-1.3-7.9c-1.1-2.4-2.7-4.3-4.8-5.6c-2.1-1.4-4.3-2.1-6.6-2.1c-3.5,0-6.7,1.4-9.5,4.1
					C431.7,448.4,430.1,452.2,429.6,457.2z"
      />
      <path
        fill="#D8005F"
        d="M495.1,449.5c6.9-8.3,13.4-12.4,19.7-12.4c3.2,0,6,0.8,8.3,2.4c2.3,1.6,4.1,4.2,5.5,7.9
					c0.9,2.6,1.4,6.5,1.4,11.8v25.1c0,3.7,0.3,6.2,0.9,7.6c0.5,1.1,1.2,1.9,2.3,2.5c1,0.6,3,0.9,5.8,0.9v2.3h-29v-2.3h1.2
					c2.7,0,4.6-0.4,5.7-1.2c1.1-0.8,1.8-2.1,2.3-3.7c0.2-0.6,0.3-2.6,0.3-6v-24c0-5.3-0.7-9.2-2.1-11.6c-1.4-2.4-3.7-3.6-7-3.6
					c-5.1,0-10.1,2.8-15.2,8.3v31c0,4,0.2,6.4,0.7,7.4c0.6,1.2,1.4,2.1,2.5,2.7c1,0.6,3.2,0.9,6.4,0.9v2.3h-29v-2.3h1.3
					c3,0,5-0.8,6.1-2.3c1-1.5,1.6-4.4,1.6-8.7v-21.8c0-7-0.2-11.3-0.5-12.9c-0.3-1.5-0.8-2.6-1.5-3.1c-0.7-0.6-1.5-0.8-2.7-0.8
					c-1.2,0-2.6,0.3-4.3,1l-1-2.3l17.7-7.2h2.8V449.5z"
      />
      <path
        fill="#D8005F"
        d="M585.1,490.9c-2.9,3-5.7,5.1-8.4,6.4c-2.7,1.3-5.7,2-8.8,2c-6.4,0-12-2.7-16.8-8
					c-4.8-5.4-7.2-12.2-7.2-20.7c0-8.4,2.6-16.1,7.9-23.1c5.3-7,12.1-10.5,20.4-10.5c5.2,0,9.4,1.6,12.8,4.9v-10.8
					c0-6.7-0.2-10.8-0.5-12.4c-0.3-1.5-0.8-2.6-1.5-3.1c-0.7-0.6-1.5-0.8-2.6-0.8c-1.1,0-2.6,0.3-4.4,1l-0.8-2.2l17.5-7.2h2.9v67.9
					c0,6.9,0.2,11.1,0.5,12.6c0.3,1.5,0.8,2.6,1.5,3.2c0.7,0.6,1.5,0.9,2.5,0.9c1.2,0,2.7-0.4,4.6-1.1l0.7,2.2l-17.4,7.2h-2.9V490.9
					z M585.1,486.4v-30.2c-0.3-2.9-1-5.6-2.3-7.9c-1.3-2.4-3-4.2-5.1-5.4c-2.1-1.2-4.2-1.8-6.2-1.8c-3.8,0-7.1,1.7-10.1,5.1
					c-3.9,4.4-5.8,10.9-5.8,19.5c0,8.6,1.9,15.2,5.6,19.8c3.8,4.6,7.9,6.9,12.6,6.9C577.7,492.2,581.4,490.3,585.1,486.4z"
      />
      <path
        fill="#D8005F"
        d="M629.4,406.3v77.9c0,3.7,0.3,6.1,0.8,7.3c0.5,1.2,1.4,2.1,2.5,2.7c1.1,0.6,3.2,0.9,6.2,0.9v2.3h-28.8v-2.3
					c2.7,0,4.5-0.3,5.5-0.8c1-0.6,1.8-1.5,2.3-2.8c0.6-1.3,0.8-3.7,0.8-7.4v-53.3c0-6.6-0.2-10.7-0.4-12.2c-0.3-1.5-0.8-2.6-1.4-3.1
					c-0.7-0.6-1.5-0.8-2.5-0.8c-1.1,0-2.5,0.3-4.2,1l-1.1-2.2l17.5-7.2H629.4z"
      />
      <path
        fill="#D8005F"
        d="M642.4,438.8h27.4v2.4h-1.3c-1.9,0-3.4,0.4-4.3,1.2c-1,0.8-1.4,1.9-1.4,3.1c0,1.7,0.7,4,2.1,6.9L679,482
					l13.1-32.4c0.7-1.8,1.1-3.5,1.1-5.2c0-0.8-0.2-1.3-0.4-1.7c-0.3-0.5-0.9-0.8-1.6-1.1c-0.7-0.3-2-0.4-3.8-0.4v-2.4h19.1v2.4
					c-1.6,0.2-2.8,0.5-3.7,1c-0.9,0.5-1.8,1.5-2.8,2.9c-0.4,0.6-1.1,2.2-2.2,4.9l-23.9,58.6c-2.3,5.7-5.3,10-9.1,12.9
					c-3.7,2.9-7.3,4.4-10.8,4.4c-2.5,0-4.6-0.7-6.2-2.2c-1.6-1.5-2.4-3.1-2.4-5c0-1.8,0.6-3.2,1.8-4.3c1.2-1.1,2.8-1.6,4.8-1.6
					c1.4,0,3.3,0.5,5.8,1.4c1.7,0.6,2.8,1,3.2,1c1.3,0,2.7-0.7,4.2-2c1.5-1.3,3-3.9,4.6-7.7l4.2-10.2l-21.1-44.3
					c-0.6-1.3-1.7-3-3.1-4.9c-1.1-1.5-1.9-2.5-2.6-3c-1-0.7-2.6-1.3-4.7-1.8V438.8z"
      />
      <rect x="158.2" y="536.9" fill="#D8005F" width="707.7" height="4.3" />
      <path
        fill="#888888"
        d="M422.2,595.5l0.3,0.4L422.2,595.5c1.8-0.9,3.1-2.1,4-3.7c0.9-1.6,1.4-3.6,1.4-5.9v-5.6
				c0-2.2-0.5-4.1-1.4-5.7c-0.9-1.6-2.2-2.8-3.9-3.6c-1.7-0.8-3.9-1.2-6.3-1.2H405v47.3h4.6v-20.4h6.4c0.3,0,0.5,0,0.8,0l0.7,0
				l6.1,20.5h5.2l-7-21.4L422.2,595.5z M419.9,591.7c-1,0.5-2.2,0.8-3.5,0.8h-6.8v-18.4h6.8c1.4,0,2.5,0.3,3.5,0.8
				c1,0.5,1.8,1.4,2.3,2.4c0.5,1,0.8,2.3,0.8,3.7v5c0,1.3-0.3,2.5-0.8,3.5C421.6,590.4,420.9,591.2,419.9,591.7z"
      />
      <rect x="441.4" y="569.9" fill="#888888" width="4.6" height="47.3" />
      <path
        fill="#888888"
        d="M477.1,570.7c-1.7-0.9-3.8-1.4-6.2-1.4c-2.4,0-4.5,0.5-6.2,1.4c-1.7,0.9-3,2.2-3.9,3.9
				c-0.9,1.7-1.4,3.9-1.4,6.4v25c0,2.5,0.5,4.6,1.4,6.3c0.9,1.7,2.2,3,3.9,3.9c1.7,0.9,3.8,1.4,6.2,1.4c2.4,0,4.5-0.5,6.2-1.4
				c1.7-0.9,3-2.2,3.9-3.9c0.9-1.7,1.4-3.9,1.4-6.4v-25c0-2.5-0.5-4.6-1.4-6.4C480.1,573,478.8,571.6,477.1,570.7z M477.7,606.2
				c0,1.4-0.3,2.7-0.8,3.7c-0.6,1.1-1.4,1.9-2.4,2.4c-1,0.5-2.3,0.8-3.7,0.8c-1.4,0-2.7-0.3-3.7-0.8c-1-0.6-1.9-1.4-2.4-2.4
				c-0.5-1-0.8-2.3-0.8-3.7v-25.4c0-1.4,0.3-2.7,0.8-3.7c0.6-1.1,1.4-1.9,2.4-2.4c1-0.5,2.3-0.8,3.7-0.8c1.4,0,2.7,0.3,3.7,0.8
				c1,0.6,1.9,1.4,2.4,2.4c0.5,1,0.8,2.3,0.8,3.7V606.2z"
      />
      <polygon
        fill="#888888"
        points="518.9,570.4 514.5,570.4 514.5,617.7 518.8,617.7 518.8,584.9 519.8,584.8 527.8,610.4 531.2,610.4 
				539.4,583.9 540.4,584 540.4,617.7 544.6,617.7 544.6,570.4 540.3,570.4 529.5,604.2 			"
      />
      <path
        fill="#888888"
        d="M578.8,571.2c-1.4-1-3.2-1.5-5.2-1.5c-3.1,0-5.3,1.1-7,3.4c-1.6,2.2-2.5,5.3-2.5,9.2l4.4,0
				c0-2.1,0.5-3.8,1.2-5c0.9-1.4,2.1-2,3.7-2c1.8,0,3.2,0.7,4.1,2c0.9,1.3,1.4,3.1,1.4,5.4v6.7h-5.5c-3.4,0-5.9,1.2-7.6,3.7
				c-1.7,2.5-2.6,6.5-2.6,11.7c0,4.3,0.7,7.6,2.1,9.7c1.4,2.1,3.4,3.1,6.2,3.1c1.6,0,2.9-0.4,3.9-1.1c1-0.7,1.8-1.8,2.3-3.2
				c0-0.1,0.1-0.2,0.1-0.4l0.1-0.2l0.9,0.1v4.1h4.3v-34.3c0-2.8-0.4-5.2-1.2-7.1C581.4,573.6,580.3,572.2,578.8,571.2z M579,603.6
				c0,2.9-0.5,5-1.5,6.5c-1,1.5-2.5,2.3-4.6,2.3c-1.8,0-3.2-0.6-4.1-1.9c-0.9-1.2-1.3-3.1-1.3-5.6c0-3.3,0.5-5.8,1.4-7.5
				c1-1.8,2.5-2.6,4.5-2.6h5.5V603.6z"
      />
      <path
        fill="#888888"
        d="M605.8,577.7l-0.9-0.2v-6.3h-4.6v46.5h4.6v-29.5c0.1-3.5,1-6.3,2.8-8.3c1.7-2,4.2-3,7.2-3h0.2v-6.4
				c-2,0.1-3.8,0.9-5.5,2.3C608.2,573.8,606.9,575.6,605.8,577.7z"
      />
      <polygon
        fill="#888888"
        points="649.1,571.1 644.2,571.1 632.5,596.7 631.6,596.5 631.6,570.1 627,570.1 627,617.7 631.6,617.7 
				631.6,606.8 637.6,594.6 645.4,617.7 650.5,617.7 640.2,589.2 			"
      />
      <path
        fill="#888888"
        d="M665,609c-0.9-1.5-1.4-3.7-1.4-6.4v-6.3h15.1v-11.6c0-3.2-0.4-6-1.2-8.2c-0.8-2.2-1.9-3.9-3.4-5
				c-1.4-1.1-3.2-1.7-5.3-1.7c-2,0-3.8,0.6-5.2,1.9c-1.4,1.2-2.6,3.1-3.4,5.5c-0.8,2.4-1.2,5.4-1.2,8.9v16.5c0,3.3,0.4,6.1,1.2,8.4
				c0.8,2.3,1.9,4,3.4,5.2c1.5,1.2,3.3,1.7,5.4,1.7c3.1,0,5.4-1.1,7.1-3.4c1.7-2.2,2.5-5.5,2.6-9.6l-4.5,0c0,2.1-0.4,3.8-1.3,4.9
				c-0.9,1.3-2.2,1.9-3.9,1.9C667.3,611.5,665.9,610.7,665,609z M663.6,585.4c0-3,0.4-5.4,1.3-7c0.9-1.8,2.3-2.7,4-2.7
				c1.7,0,3.1,0.8,4,2.5c0.9,1.6,1.3,3.8,1.3,6.6v5.8h-10.7V585.4z"
      />
      <path
        fill="#888888"
        d="M706.5,577.1v-6.2h-8.7l-5.1-0.3v34.9c0,4,0.6,7.1,1.9,9.1c1.2,2,3.1,2.9,5.7,2.9h2.8v-6.2H701
				c-1.2,0-2.2-0.6-2.8-1.6c-0.6-1-0.9-2.4-0.9-4.2v-28.5H706.5z"
      />
    </g>
  </svg>
);
