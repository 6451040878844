import React from "react";
// import Svg, { Path } from "react-native-svg";

export const LogoCoconut = () => (
  <svg width={106} height={65} viewBox="0 0 391.955 219.92">
    <path
      fill="#ff8400"
      d="M283.211 17.406c-39.964 0-74.144 25.166-87.573 60.474h9.699c12.906-30.242 42.912-51.444 77.874-51.444 46.746 0 84.637 37.892 84.637 84.635 0 46.745-37.891 84.638-84.637 84.638-34.961 0-64.965-21.2-77.873-51.443h-9.698c13.429 35.309 47.607 60.474 87.571 60.474 51.648 0 93.668-42.017 93.668-93.67 0-51.646-42.02-93.664-93.668-93.664"
    />
    <path
      fill="#58595b"
      d="M13.899 87.672h23.148v8.39H22.333v10.724H36.37v8.389H22.333v19.113H13.9zM49.24 111.012c0 8.642 6.956 16.024 15.268 16.024 8.31 0 15.266-7.382 15.266-16.024 0-8.768-6.77-15.833-15.266-15.833s-15.268 7.065-15.268 15.833m38.97.19c0 13.5-10.774 24.225-23.702 24.225-12.93 0-23.703-10.725-23.703-24.224 0-13.563 10.59-24.413 23.703-24.413s23.701 10.85 23.701 24.413M100.656 111.012c0 8.642 6.956 16.024 15.268 16.024 8.31 0 15.266-7.382 15.266-16.024 0-8.768-6.77-15.833-15.266-15.833s-15.268 7.065-15.268 15.833m38.97.19c0 13.5-10.774 24.225-23.702 24.225-12.928 0-23.703-10.725-23.703-24.224 0-13.563 10.59-24.413 23.703-24.413s23.702 10.85 23.702 24.413M154.224 125.9c4.74 0 9.11-.19 13.052-3.154 3.508-2.776 5.478-7.002 5.478-11.543 0-4.859-2.092-9.588-6.032-12.364-3.818-2.65-8.497-2.776-12.93-2.776h-.615V125.9zm-9.482-38.226h9.543c6.772.062 13.728.756 19.085 5.424 4.987 4.29 7.819 11.481 7.819 18.105 0 6.496-2.895 13.437-7.819 17.725-5.478 4.794-11.944 5.362-18.9 5.362h-9.728zM193.016 121.168c0 3.723 2.956 5.867 6.34 5.867 3.202 0 5.91-2.459 5.91-5.867 0-5.11-4.185-6.622-7.94-7.885-3.017-1.008-5.602-1.892-7.88-4.29-2.217-2.333-3.57-5.74-3.57-8.957 0-7.632 6.156-13.246 13.42-13.246 7.018 0 13.36 5.93 13.298 13.246h-8.681c-.123-2.901-2.463-4.857-5.11-4.857-2.77 0-4.555 2.145-4.494 4.92-.061 3.407 3.017 4.732 5.725 5.679 3.203 1.134 6.28 2.08 8.865 4.289 3.263 2.65 4.803 6.055 4.803 10.597 0 8.327-6.712 14.762-14.776 14.762-7.757 0-14.713-6.12-14.713-14.258z"
    />
    <path
      fill="#ff8400"
      d="M274.835 101.328c-2.71-3.848-7.389-6.182-11.944-6.182a15.824 15.824 0 00-10.526 4.037c-3.14 2.839-5.234 7.444-5.234 11.733 0 3.912 1.724 8.074 4.371 10.85 3.079 3.342 7.388 5.234 11.759 5.234 5.356 0 10.834-3.406 13.297-8.388h-19.023v-8.137h29.674v1.01c0 13.623-11.204 23.905-24.194 23.905-13.113 0-24.318-10.471-24.318-24.16 0-13.75 11.142-24.474 24.194-24.474 9.234 0 18.592 5.615 22.04 14.572zM301.44 109.59c-1.909 1.957-2.833 4.48-2.833 7.256 0 5.612 4.248 10.344 9.85 10.344 5.788 0 10.036-4.478 10.036-10.344 0-5.74-4.371-10.472-10.035-10.472-2.524 0-5.295 1.325-7.019 3.217m25.242 7.255c0 10.344-8.188 18.544-18.223 18.544-9.85 0-18.039-8.39-18.039-18.544 0-10.472 8.188-18.672 18.41-18.672 10.033 0 17.852 8.516 17.852 18.672"
    />
  </svg>
);
