import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoHEB } from "src/components/LogoHEB";

class HebFlavor extends Flavor {
  getMainColor() {
    return color2.HEB_RED;
  }

  getDashLineColor() {
    return color2.HEB_RED;
  }

  getCircleLogo() {
    return <LogoHEB />;
  }
}

const Singleton = new HebFlavor();
export default Singleton;
