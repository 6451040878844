import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoNewJerseyDevils } from "src/components/LogoNewJerseyDevils";

class NewJerseyDevils extends Flavor {
  getMainColor() {
    return color2.NEW_JERSEY_DEVILS;
  }

  getDashLineColor() {
    return color2.NEW_JERSEY_DEVILS;
  }

  getCircleLogo() {
    return <LogoNewJerseyDevils />;
  }
}

const Singleton = new NewJerseyDevils();
export default Singleton;
