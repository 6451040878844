import React from 'react'
import { Flavor } from './flavor'
import { LogoChoice } from 'src/components/LogoChoice'
import { color2 } from "src/styles";
import { timezoneDate } from '../utils';
import { IFeatureFlags } from '../../../../shared/interface';

class ChoiceFlavor extends Flavor {
  getMainColor() {
    return color2.BLACK
  }

  getFeatureFlags(): IFeatureFlags {
    return {
      ...super.getFeatureFlags(),
    }
  }

  getDashLineColor() {
    return color2.BLACK
  }

  getCircleLogo() {
    return <LogoChoice />
  }

  formatDateTime(dateTime: string, timezone: string) {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "MM-DD-YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }
}

const Singleton = new ChoiceFlavor()
export default Singleton
