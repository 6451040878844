import React from "react";
import { View, Text, ViewProps, TouchableOpacity } from "react-native";
import { styles } from "./style";
import { IUseFlavor, withFlavor } from "src/lib/hooks/useFlavor";

type ITagProps = ViewProps &
  IUseFlavor & {
    title: string;
    tagColor?: string;
    tagBackgroundColor?: string;
    tagInfo: string;
    onTagInfo?: (info: string) => void;
  };

export const TagEl: React.FC<ITagProps> = ({
  title,
  tagColor,
  tagBackgroundColor,
  tagInfo,
  onTagInfo,
  flavor,
  ...props
}: ITagProps) => {
  const css = styles(flavor);

  const wrapperStyle = [
    css.tagWrapper,
    props.style,
    tagBackgroundColor ? { backgroundColor: tagBackgroundColor } : null,
  ];
  const titleStyle = [css.title, tagColor ? { color: tagColor } : null];
  return (
    <TouchableOpacity
      style={css.wrapper}
      onPress={() => onTagInfo && onTagInfo(tagInfo)}
    >
      <View style={wrapperStyle} {...props}>
        <Text style={titleStyle}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

export const Tag = withFlavor(TagEl);
