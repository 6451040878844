import React from 'react'
import { Flavor } from './flavor'
import { IFeatureFlags } from '../../../../shared/interface';
import { color2 } from "src/styles";
import { timezoneDate } from '../utils';
import {LogoLevy} from "../../components/LogoLevy";

class LevyFlavor extends Flavor {
    getMainColor() {
        return color2.LEVY_MAIN
    }

    getFeatureFlags(): IFeatureFlags {
        return {
            ...super.getFeatureFlags(),
        }
    }

    getDashLineColor() {
        return color2.LEVY_MAIN
    }

    getCircleLogo() {
        return <LogoLevy />
    }

    formatDateTime(dateTime: string, timezone: string) {
        const date = timezoneDate(dateTime, timezone)
        const formatDate = "MM-DD-YYYY";
        const formatTime = "HH:mm";

        return {
            date: date.format(formatDate),
            time: date.format(formatTime),
        };
    }
}

const Singleton = new LevyFlavor()
export default Singleton
