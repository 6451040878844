import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoAVFC } from "src/components/LogoAVFC";

class AVFCFlavor extends Flavor {
  getMainColor() {
    return color2.ASTON_VILLA;
  }

  getDashLineColor() {
    return color2.ASTON_VILLA;
  }

  getCircleLogo() {
    return <LogoAVFC />;
  }
}

const Singleton = new AVFCFlavor();
export default Singleton;
