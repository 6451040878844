import React from "react";
import { Col, Row } from "reactstrap";
import cardDots from "src/assets/images/instructions/apple/card_dots.jpg";
import { translate } from "src/lib/i18n";

export const ApplePayInstruction: React.FC = () => {
  return (
    <Row className="justify-content-center mb-3">
      <Col sm={3}>
        <p className="fw-bolder">{translate("receiptsHome.instructionApple")}</p>
      </Col>
      <Col sm={4}>
        <img style={{ width: "100%" }} src={cardDots} alt="" />
      </Col>
    </Row>
  );
};
