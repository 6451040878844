import * as React from "react";

export default function BankStatementIcon(props: { color: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 103.61 122.88"
      width='100%'
      height='100%'
      {...props}
    >
      <path
        fill={props.color}
        d="M99.16,113.91l-2.08-2.39-11.2,11.2L74.49,111.64l-11.2,10.75L51.57,111.56l-11,11.32L29.26,111.57l-11.58,11L6.61,111.41,0,119V0H103.61V119l-4.45-5.12ZM89.31,44.66a2,2,0,0,1,0,4.06h-19a2,2,0,0,1,0-4.06ZM86.09,73.74a2.21,2.21,0,1,1,0,4.41H17.51a2.21,2.21,0,0,1,0-4.41ZM76,88.37a2.21,2.21,0,0,1,0,4.41H17.51a2.21,2.21,0,0,1,0-4.41ZM86.09,59.11a2.21,2.21,0,1,1,0,4.41H17.51a2.21,2.21,0,0,1,0-4.41ZM16.81,27.37,37.72,11.11l21,16.26Zm-.22,18.14h2.46l1.84-1.39V32.75h-2.8V29.66H57.35v3.09h-2.8V44.12l1.84,1.39h2.46v4.7H16.59v-4.7Zm13.8-1.39V32.75H26.06V44.12Zm9.49,0V32.75H35.55V44.12Zm9.5,0V32.75H45.05V44.12ZM89.31,30a2,2,0,0,1,0,4.06h-19a2,2,0,0,1,0-4.06Zm9.23,75.46V5.07H5.07V105.48l1.3-1.5,11.42,11.53,11.57-11,11.17,11.17L51.38,104.5l11.85,10.94,11.28-10.83,11.32,11,11.5-11.51,1.21,1.39Z"
      />
    </svg>
  );
}
