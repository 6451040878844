import React from "react";
import { Row, Col } from "reactstrap";
import card from "src/assets/images/instructions/google/card.jpeg";
import { translate } from "src/lib/i18n";

export const GooglePayInstructions: React.FC = () => {
  return (
    <Row className="justify-content-center mb-3">
      <Col sm={3}>
        <p className="fw-bolder">{translate("receiptsHome.instructionGoogle")}</p>
      </Col>
      <Col sm={4}>
        <img style={{ width: "100%" }} src={card} alt="" />
      </Col>
    </Row>
  );
};
