import { Flavor } from "./flavor";
import { color2 } from "src/styles";

class LoopFlavor extends Flavor {
  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      isOrderDetailQrCodeEnabled: true,
    };
  }
}

const Singleton = new LoopFlavor();
export default Singleton;
