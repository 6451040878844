import { View } from "react-native";
import { useReceiptOrder } from "../../ReceiptContext";
import {
  cssStyles as footerStyles,
} from "../ReceiptFooter/styles";

export function ExtraTextBlock() {
  const order = useReceiptOrder()
  if (order.extraText === null) {
    return null
  }

  return (
    <View>
      <div style={footerStyles.extraText}>{order.extraText}</div>
    </View>
  )
}