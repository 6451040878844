import React from "react";
// import Svg, { ClipPath, Defs, G, Path } from "react-native-svg";
import { Wrap } from "./styles";

export const LogoZabka = React.memo(() => (
  <Wrap>
    <svg width={320} height={100} viewBox="0 0 630 200">
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 1080h1920V0H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#prefix__a)"
        transform="matrix(1.33333 0 0 -1.33333 0 200)"
      >
        <path
          d="M101.774 22.803c19.94 0 38.37 6.593 53.225 17.714l-2.075 9.868c-14.591-9.038-32.202-14.331-51.15-14.331-18.951 0-36.563 5.295-51.156 14.335l-2.075-9.867c14.855-11.123 33.287-17.72 53.23-17.72m73.214 61.503c10.446 0 13.08 7.34 13.08 16.657h12.422c.282-9.6-1.883-17.597-10.822-22.303l12.985-21.643v-1.317h-13.267l-10.822 18.82c-1.13-.376-2.352-.565-3.764-.565h-3.011V55.699h-13.08v60.635h13.08v-32.03zm63.542 5.133c-3.088 1.384-5.93 1.925-8.564 1.832-6.116-.283-10.821-3.483-10.821-13.646 0-9.221 4.799-12.233 11.48-12.327 2.447-.093 5.082.377 7.905 1.13zm12.797-31.276c-5.558-.827-10.47-3.41-19.197-3.498-13.832-.14-26.16 5.269-26.16 22.96 0 15.715 9.128 24.279 21.738 24.279 12.942 0 23.619-5.188 23.619-5.188zM83.398 89.438c-3.088 1.384-5.93 1.925-8.564 1.832-6.117-.283-10.822-3.483-10.822-13.646 0-9.221 4.8-12.233 11.48-12.327 2.448-.093 5.082.377 7.906 1.13zm12.797-31.276c-5.558-.827-10.47-3.41-19.197-3.498-13.832-.14-26.16 5.269-26.16 22.96 0 15.715 9.127 24.279 21.738 24.279 12.942 0 23.619-5.188 23.619-5.188zm22.279 8.265c2.823-.753 5.458-1.223 7.905-1.13 6.68.094 11.48 3.106 11.48 12.327 0 10.163-4.705 13.363-10.821 13.646-2.635.093-5.553-.66-8.564-2.353zm-12.797 49.813h13.079V99.738c3.67 1.506 7.247 2.165 10.54 2.165 12.61.095 21.737-8.564 21.737-24.28 0-17.69-12.327-22.814-26.16-22.96-7.774-.081-12.76 2.578-19.196 3.577zm-85.352-4.981c0 4.009 3.119 6.594 6.95 6.594 4.01 0 7.129-2.585 7.129-6.594 0-3.921-3.118-6.416-7.128-6.416-3.832 0-6.951 2.495-6.951 6.416M45.53 99.456L25.77 66.99h19.76V55.605H6.763v1.506l20.043 32.935H8.173v10.916H45.53zM417.757 36.031H309.353c-22.794 0-41.443 18.65-41.443 41.443 0 22.795 18.649 41.444 41.443 41.444h108.404c22.794 0 41.444-18.649 41.444-41.444 0-22.794-18.65-41.443-41.444-41.443"
          fill="#0c6a36"
        />
        <path
          d="M371.184 62.32c6.433 0 12.793 4.605 12.793 13.598 0 8.92-6.433 13.304-12.866 13.304s-12.794-4.385-12.794-13.304c0-8.993 6.433-13.598 12.867-13.598m-.22-4.752c-9.21.073-18.422 6.141-18.422 18.35 0 12.281 9.357 18.203 18.642 18.203 9.284 0 18.495-5.995 18.495-18.203 0-12.209-9.357-18.423-18.715-18.35M425.79 58.227v20.835c0 6.36-4.094 10.308-9.87 10.308-5.994 0-10.526-4.094-10.526-10.016V58.227h-5.703v25.148c0 4.75-.365 5.995-4.093 5.629l-.074 4.533c4.094.585 9.504 1.46 9.504-5.337 3.144 4.752 7.457 5.922 12.428 5.922 9.284 0 14.109-6.434 14.109-15.06V58.227zM341.884 77.475c0-16.228-13.155-29.383-29.383-29.383s-29.384 13.155-29.384 29.383 13.156 29.383 29.384 29.383 29.383-13.155 29.383-29.383"
          fill="#fff"
        />
      </g>
    </svg>
  </Wrap>
));
