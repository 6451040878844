enum requiredVariables {
  "REACT_APP_NAME",
}

type RequiredVariables = keyof typeof requiredVariables;
type Config = Readonly<{ [key in RequiredVariables]: string }>;

// @ts-ignore
const ensureConfig = (env): Config => {
  const requiredEnvs = Object.values(requiredVariables).reduce(
    (acc, val) => {
      // Filters index entries from enum.
      if (typeof val === "number") {
        return acc;
      }

      if (!env[val]) {
        throw new Error(`Missing '${val}' environment variable.`);
      }

      return { ...acc, [val]: env[val] };
    },
    { REACT_APP_NAME: "" }
  );

  return { ...requiredEnvs };
};

export default ensureConfig(process.env);
