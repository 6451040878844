import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoVerizonUltra } from "src/components/LogoVerizonUltra";

class VerizonUltraUKFlavor extends Flavor {
  getMainColor() {
    return color2.VERIZONULTRAUK_MAIN;
  }

  getDashLineColor() {
    return color2.VERIZONULTRAUK_MAIN;
  }

  getCircleLogo() {
    return <LogoVerizonUltra />;
  }
}

const Singleton = new VerizonUltraUKFlavor();
export default Singleton;
