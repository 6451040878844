import { StyleSheet } from "react-native";
import { dpToPx, color2 } from "src/styles";
import { font } from "src/styles/themes";
import { Flavor } from "src/lib/flavors";

export const styles = (flavor: Flavor) =>
  StyleSheet.create({
    wrapper: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: dpToPx(20),
    },

    wrapperInput: {
      marginLeft: dpToPx(8),
    },

    input: {
      color: color2.BLUE_DARK,
      fontFamily: flavor.getPrimaryFontLightest(),
      fontSize: font.size.input,
      width: "100%",
    },
  });
