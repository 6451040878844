import { useMachine } from "@xstate/react";
import React from "react";
import { useParams } from "react-router-dom";
import { Row } from "reactstrap";
import { Spinner } from "src/components/Spinner";
import { RequestStatus } from "src/lib/api/types";
import { useApi } from "src/lib/ApiProvider";
import { navigationMachine } from "./navigation";

import { ReceiptDetail } from "./ReceiptDetail";
import ReceiptDetailComplain from "./ReceiptDetailComplain";
import ReceiptDetailComplainConfirmation from "./ReceiptDetailComplainConfirmation";
import { ReceiptContextProvider } from "./ReceiptContext";

export const Receipt: React.FC = () => {
  const [current, send] = useMachine(navigationMachine);
  const { authApi } = useApi();
  const { sessionToken, orderId } = useParams();
  const [order, setOrder] = React.useState<ApiOrderDetail>();
  const [requestStatus, setRequestStatus] =
    React.useState<RequestStatus>("idle");

  const fetchOrder = React.useCallback(
    async (orderId: string) => {
      setRequestStatus("loading");
      const response = await authApi.get<ApiOrderDetail>(
        `api/customer/v2/orders/${orderId}`
      );
      setOrder({
        ...response.data,
        draftCreatedAt: response.data.draftCreatedAt ?? response.data.createdAt,
      });
      setRequestStatus("success");
    },
    [authApi]
  );

  React.useEffect(() => {
    if (orderId && sessionToken) {
      fetchOrder(orderId);
    }
  }, [authApi, orderId, sessionToken, fetchOrder]);

  const onContest = React.useCallback(() => {
    send("CONTEST");
  }, [send]);

  return requestStatus === "success" && order ? (
    <>
      <ReceiptContextProvider order={order} onContest={onContest}>
        {current.matches("detail") && (
          <ReceiptDetail />
        )}
        {current.matches("contest") && (
          <ReceiptDetailComplain
            onBack={() => {
              send("BACK");
              if (orderId) {
                fetchOrder(orderId);
              }
            }}
            onContestSuccess={() => {
              send("CONTEST_MESSAGE");
            }}
          />
        )}
        {current.matches("contestMsg") && (
          <ReceiptDetailComplainConfirmation
            onBack={() => {
              if (orderId) {
                fetchOrder(orderId);
              }
              send("BACK");
            }}
          />
        )}
      </ReceiptContextProvider>
    </>
  ) : (
    <Row className="justify-content-center" style={{ marginTop: "100px" }}>
      <Spinner />
    </Row>
  );
};
