import ReceiptDetailSection from "../../../../components/v2/ReceiptsDetailSection";
import { useReceiptOrder } from "../../ReceiptContext";
import { MasterContext } from '../../../../lib/masterContext'
import { useContext } from "react";
import { useCurrencyFormatter } from "src/lib/hooks/useCurrencyFormatter";
import { CardDataBlockSettings } from "../../../../../../web-receipt-api/src/lib/validateReceiptStructure";

export type CardDataBlockProps = {
  blockSettings: CardDataBlockSettings
}

export const CardDataBlock = (props: CardDataBlockProps) => {
  const {blockSettings} = props
  const order = useReceiptOrder()
  const {
    featureFlags: { hideCardBrand },
  } = useContext(MasterContext);

  const {formatCurrency} = useCurrencyFormatter(order.currencyCode, blockSettings.currencyFormat)

  if (hideCardBrand) {
    return null
  }

  const cardBrand = order.paymentInfo?.paymentInstrument.cardBrand;
  const cardData = cardBrand && [
    {
      title: cardBrand,
      value: formatCurrency(order.totalPrice),
      valueTall: true,
    },
  ];

  return cardData ? <ReceiptDetailSection data={cardData} /> : null;
};
