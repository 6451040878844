import React, { useContext } from "react";
import ReceiptDetailSection from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { MasterContext } from "src/lib/masterContext";
import { formatDateTime } from "src/lib/utils";
import { useReceiptOrder } from "../../ReceiptContext";


export const OrderIdWithDateBlock = () => {
  const order = useReceiptOrder()
  const {
    appConfig: { timezone },
  } = useContext(MasterContext);
  const created = formatDateTime(order.draftCreatedAt, timezone);

  const items = [{
    title: (translate("receiptDetailScreen.orderId") as string) + " " + order.id,
    value: `${created.date}\n${created.time}`,
    leftViewStyle: { marginBottom: "auto" },
  }];

  return (
    <ReceiptDetailSection data={items} />
  );
};
