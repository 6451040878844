import { color2 } from "../../styles";
import { timezoneDate } from "../utils";
import { Flavor } from "./flavor";

class AiFiFlavor extends Flavor {
  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }

  formatTableDateTime(dateTime: string, timezone: string): { date: string; time: string } {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "M/DD/YY";
    const formatTime = "hh:mma";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }
}

const Singleton = new AiFiFlavor();
export default Singleton;
