import { Text, View } from "react-native";
import { translate } from "../../../../lib/i18n";
import { styles } from "../ReceiptFooter/styles";
import { useReceiptOrder } from "../../ReceiptContext";

export const FooterOrderIdBlock = () => {
  const order = useReceiptOrder();

  return (
    <View style={styles.addressWrapper}>
      <Text style={styles.address}>
        {`${translate("receiptDetailScreen.orderId")}\n`}
        {order.id}
      </Text>
      <Text style={styles.star}>* * *</Text>
    </View>
  );
};
