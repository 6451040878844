import { LogoPhoenixSuns } from "src/components/LogoPhoenixSuns";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class PhoenixSuns extends Flavor {
  getMainColor() {
    return color2.PHOENIX_SUNS;
  }

  getDashLineColor() {
    return color2.PHOENIX_SUNS;
  }

  getCircleLogo() {
    return <LogoPhoenixSuns />;
  }
}

const Singleton = new PhoenixSuns();
export default Singleton;
