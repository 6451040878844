import React, { useMemo } from "react"

export type ReceiptContextValue = {
  order: ApiOrderDetail,
  onContest: () => void
}

const ReceiptContext = React.createContext<ReceiptContextValue | null>(null)

export type ReceiptContextProviderProps = {
  order: ApiOrderDetail
  onContest: () => void
  children: React.ReactNode
}

export const ReceiptContextProvider = (props: ReceiptContextProviderProps) => {
  const {order, onContest, children} = props
  const value = useMemo(()=>({
    order,
    onContest
  }), [order, onContest])

  return (
    <ReceiptContext.Provider value={value}>
      {children}
    </ReceiptContext.Provider>
  )
}

export function useReceipt() {
  const receiptContext = React.useContext(ReceiptContext)
  if (!receiptContext) {
    throw new Error('useReceipt requires <ReceiptContextProvider/> ancestor')
  }
  return receiptContext
}

export function useReceiptOrder() {
  return useReceipt().order
}
