import { ITranslation } from "./types";

export const pl: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      'Twój rachunek jest przetwarzany, proszę sprawdź ponownie za kilka minut.<br/>W przypadku problemów, skontaktuj się z wsparciem pod adresem <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
    errorReachOutSupportMsg:
      'In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  api: {
    E_FORCED_LOGOUT: "Zostałeś wylogowany z powodu braku dostępu.",
  },
  orderStatus: {
    draft: "Oczekuje na płatność",
    contested: "Rozpatrywany",
    paid: "Zapłacony",
    unpaid: "Niezapłacony",
    pending: "Oczekuje na weryfikację",
    reviewed: "Zweryfikowany",
  },
  receiptsList: {
    headings: {
      date: "Data",
      time: "Godzina",
      status: "Status",
      total: "Suma",
    },
    action: {
      view: "Otwórz",
    },
    statusTooltip: {
      draft: "Oczekuje na płatność - zamówienie jest przetwarzane",
      paid: "Zapłacone - zamówienie zostało przetworzone i jest opłacone",
      paymentProcessing:
        "Przetwarzanie płatności - zamówienie zostało przetworzone, płatność jest w trakcie realizacji",
      unpaid:
        "Niezapłacone - zamówienie zostało przetworzone, płatność nieudana",
      pending:
        "Oczekuje na weryfikację - kupujący zgłosił błąd, który jest rozpatrywany przez sprzedającego",
      reviewed: "Zweryfikowany - sprzedający zweryfikował zamówienie",
    },
  },
  receiptDetailScreen: {
    headerTitle: "Szczegóły rachunku",
    subtotal: "Suma",
    taxBreakdown: {
      headers: {
        rate: "%",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Podatek",
        total: "Suma:",
      },
    },
    tax: "Vat",
    taxCRV: "CRV",
    total: "Suma",
    processTime: "Czas przetwarzania",
    date: "Data",
    paymentStatus: "Status płatności",
    orderId: "Numer zamówienia",
    listOfProducts: "Zakupione produkty",
  },
  receiptDetailComplain: {
    reportProblem: "Zgłoś błąd",
    submitReview: "Wyślij do rozpatrzenia",
    confirmTitle: "Paragon wysłany",
    confirmButton: "Zrobione",
    addMissingItem: "Dodaj brakujący przedmiot",
    addMissingItemMessage: "Dodaj wiadomość (opcjonalne)",
  },
  receiptsScreen: {
    label: {
      contested: "Rozpatrywany",
      draft: "Oczekuje na płatność",
      empty: "Pusty",
      paid: "Zapłacony",
      pending: "Oczekuje na weryfikację",
      refunded: "Zwrócony",
      reviewed: "Zmieniony",
      unknown: "Nieznany",
      unpaid: "Niezapłacony",
    },
    message: {
      contested: "Zamówienie jest rozpatrywane",
      draft: "Draft message",
      empty: "Empty message",
      paid: "Paid message",
      pending: "Pending message",
      refunded: "Refunded message",
      reviewed: "Reviewed message",
      unpaid: "Zamówienie nadal nie zostało opłacone poprawnie",
    },
    minutes: "minut",
    hours: "godziny",
    days: "dni",
  },
  slideModal: {
    close: "Zamknij",
    searchPlaceholder: "Znajdź produkt",
  },
  receiptsHome: {
    receiptPortal: "Rachunki",
    selectPaymentType: "Wybierz preferowaną metodę znalezienia rachunku",
    cardBin: 'Pierwsze %{cardBinDigitsCount} cyfr numeru karty (BIN/IIN)',
    last4: "Ostatnie cztery cyfry Twojej metody płatności",
    last4Apple: "Ostatnie cztery cyfry Twojego numery Apple Pay",
    last4Google: "Ostatnie cztery cyfry Twojego numeru Google Pay",
    instructionApple:
      "Ostatnie cztery cyfry karty Apple Pay możesz znaleźć w aplikacji Portfel",
    instructionGoogle:
      "Ostatnie cztery cyfry karty Google Pay możesz znaleźć w aplikacji Portfel",
    instructionExternalId:
      'Znajdź numer referencyjny płatności w aplikacji płatniczej lub na wyciągu bankowym',
    externalIdLabel: 'Numer referencyjny płatności',
    externalIdPlaceholder: '',
    expDate: "Data ważności",
    visitDate: "Data Twojej wizyty",
    submit: "Pobierz rachunki",
    registerEmail:
      "Wpisz swój email by otrzymywać powiadomienia o przyszłych rachunkach",
    registerEmailSuccess: "Adres email zapisany pomyślnie.",
    registerEmailFail:
      "Adres email nie został zapisany, ponieważ żaden rachunek nie jest powiązany z wprowadzonymi danymi karty i/lub datą wizyty.",
    unrecognizedError: "An unrecognized error occurred",
    inconclusiveCardInfo: "We can not find your receipt",
    invalidCaptcha: "Invalid captcha, please try again",
    oasisApiError:
      "We could not connect to the receipts server. Please try again later.",
  },
};
