import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoVerizonUltra } from "src/components/LogoVerizonUltra";

class VerizonUltraFlavor extends Flavor {
  getMainColor() {
    return color2.VERIZONULTRA_MAIN;
  }

  getDashLineColor() {
    return color2.VERIZONULTRA_MAIN;
  }

  getCircleLogo() {
    return <LogoVerizonUltra />;
  }
}

const Singleton = new VerizonUltraFlavor();
export default Singleton;
