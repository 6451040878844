import React, { CSSProperties } from "react";
import { View, ViewProps } from "react-native";

import { cssStyles, styles } from "./style";

interface IBothSideTextView extends ViewProps {
  title: string;
  detailTitle?: string | null;
  detailTitleStyle?: CSSProperties;
  rightView?: React.ReactNode;
  leftView?: React.ReactNode;
  middleView?: React.ReactNode;
  leftViewStyle?: CSSProperties;
  rightViewStyle?: CSSProperties;
}

export const BothSideTextView: React.FC<IBothSideTextView> = ({
  title,
  detailTitle,
  detailTitleStyle,
  rightView,
  leftView,
  middleView,
  leftViewStyle,
  rightViewStyle,
  ...props
}) => {
  const wrapper = [styles.wrapper, props.style];
  const leftStyleCss = {
    ...cssStyles.text,
    ...cssStyles.leftText,
    ...(leftViewStyle || {}),
  }
  const rightStyleCss = {
    ...cssStyles.text,
    ...cssStyles.rightText,
    ...detailTitleStyle,
    ...rightViewStyle,
  }

  return (
    <View style={wrapper}>
      {leftView || <div style={leftStyleCss}>{title}</div>}
      {middleView && middleView}
      {rightView && rightView}
      {detailTitle && <div style={rightStyleCss}>{detailTitle}</div>}
    </View>
  );
};
