import React, { FC } from "react";

import { ActivityIndicator } from "./styles";
import { IUseFlavor, withFlavor } from "../../lib/hooks/useFlavor";

export const SpinnerEl: FC<IUseFlavor> = ({ flavor }) => (
  <ActivityIndicator color={flavor.getMainColor()} size="large" />
);

export const Spinner = withFlavor(SpinnerEl);
