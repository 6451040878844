import { LogoDACI } from "src/components/LogoDaci";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class DACIFlavor extends Flavor {
  getMainColor() {
    return color2.DACI;
  }

  getDashLineColor() {
    return color2.DACI;
  }

  getCircleLogo() {
    return <LogoDACI />
  }
}

const Singleton = new DACIFlavor();
export default Singleton;
